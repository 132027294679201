
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const meta = {
  date: '2024-02-27T17:00:00.000Z',
  title: 'Increasing velocity by modernizing a Python codebase',
  description: 'How we modernized a machine learning codebase to increase velocity, quality, and confidence',
  authors: [{
    name: 'Peyton McCullough',
    website: 'https://peytonmccullough.com',
    twitter: '',
    position: 'Engineering',
    bio: ''
  }]
};

const layoutProps = {
  meta
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h1>{`Increasing velocity by modernizing a Python codebase`}</h1>
    <p>{`When projects are new and developers are focused on shipping something quickly to prove out a concept or fill an immediate need, quality and best practices are sometimes left by the wayside. This is a tradeoff that can be worth making. But too often, temporary solutions and shortcuts become permanent, and as functionality or the number of projects grows, real issues start to surface. These issues must be solved, taking away development time. Perhaps worse, developers may begin to be wary of making changes, for fear of breaking something. The original goal may have been velocity, but at some point, velocity slows.`}</p>
    <p>{`At Ramp, we encountered this in a repository of machine learning workflows. What started as a repository for centralizing these workflows eventually became a bit of a swamp, preventing us from moving as quickly as we wanted. To reverse this, we decided to take some time to modernize the codebase and adopt industry best practices around dependency management, formatting, linting, testing, and type checking:`}</p>
    <ul>
      <li parentName="ul">{`We adopted poetry for dependency management, which allowed us to manage a complex web of dependencies and ensure consistency across environments`}</li>
      <li parentName="ul">{`We adopted ruff for formatting and linting, which allowed us to enforce consistent formatting and catch small errors`}</li>
      <li parentName="ul">{`We adopted pytest for testing, which allowed us to catch corner cases and bad assumptions`}</li>
      <li parentName="ul">{`We adopted mypy for type checking, which allowed us to catch type-related bugs`}</li>
    </ul>
    <p>{`All of this allowed us to prevent bugs and ultimately move faster and with more confidence.`}</p>
    <h2>{`The repository`}</h2>
    <p>{`Data science and machine learning happens across Ramp. However, at some point, data scientists and engineers started to develop workflows in a centralized repository. The repository was a monorepo of various unrelated projects, but sharing a single set of dependencies. This was great! Data scientists and engineers were able to collaborate in a single place and could learn from each other. Importantly, they could develop opinionated ways of doing things, and this standardization could further increase development velocity, as well as ease onboarding.`}</p>
    <p>{`However, this path isn't always easy! As the size of the codebase and the number of contributors grew, we started to encounter pain points that hindered velocity and made changes risky. We weren't able to move as quickly as we wanted to.`}</p>
    <p>{`Let's take a look at some of the problems we faced and how we solved them.`}</p>
    <h2>{`Taming a mess of dependencies with poetry`}</h2>
    <p>{`Previously, we were using a `}<a parentName="p" {...{
        "href": "https://pip.pypa.io/en/stable/reference/requirements-file-format/"
      }}><inlineCode parentName="a">{`requirements.txt`}</inlineCode></a>{` file to store project dependencies. For example:`}</p>
    <pre><code parentName="pre" {...{}}>{`pandas==2.1.4
scikit-learn==1.4.0
`}</code></pre>
    <p>{`This was a reasonable approach in the Python ecosystem some time ago, and it sufficied initially. However, problems stared to arise. The biggest problem is that it burdens developers with managing a complex web of dependencies.`}</p>
    <p>{`If you `}<inlineCode parentName="p">{`pip install`}</inlineCode>{` the dependencies above, you'll actually end up with far more dependencies than the ones listed, since those immediate, direct dependencies have their own dependencies, known as transitive dependencies. For example, `}<inlineCode parentName="p">{`pandas`}</inlineCode>{` depends on `}<inlineCode parentName="p">{`python-dateutil`}</inlineCode>{`, and `}<inlineCode parentName="p">{`python-dateutil`}</inlineCode>{` depends on `}<inlineCode parentName="p">{`six`}</inlineCode>{`. Of course, this is just one path, but with dozens of hard-coded package versions, an unruly web of transitive dependencies emerges.`}</p>
    <p>{`These transitive dependencies can lead to inconsistencies between environments. For instance, if two developers install the listed dependencies on their workstations, they may get different versions of these transitive dependencies! Worse, whenever we actually build and deploy applications, we'll get different versions there too. These inconsistencies make it difficult to debug problems, causing a ton of wasted effort by developers.`}</p>
    <p>{`We started to use poetry to improve dependency management. Poetry is widely adopted across the community, allowing us to draw upon a wealth of external resources if we need help. Rather than using `}<inlineCode parentName="p">{`requirements.txt`}</inlineCode>{`, poetry uses the newer `}<inlineCode parentName="p">{`pyproject.toml`}</inlineCode>{` standard. This file contains direct dependencies:`}</p>
    <pre><code parentName="pre" {...{}}>{`[tool.poetry.dependencies]
python = "^3.11"
pandas = "^2.1.4"
scikit-learn = "^1.4.0"
`}</code></pre>
    <p>{`These are the things we directly care about. But what about transitive dependencies? Poetry maintains a `}<inlineCode parentName="p">{`poetry.lock`}</inlineCode>{` file, which contains `}<em parentName="p">{`all`}</em>{` dependencies. It also contains hashes, so that you can be sure you're pulling the same artifacts each time.`}</p>
    <p>{`Adding or upgrade a package can be done via a simple command:`}</p>
    <pre><code parentName="pre" {...{}}>{`poetry add mlflow
`}</code></pre>
    <p>{`Behind the scenes, poetry will update the lock file.`}</p>
    <p>{`Poetry also manages virtual environments. To get started, a developer can simply clone the repository and run `}<inlineCode parentName="p">{`poetry install`}</inlineCode>{`. Poetry will create a virtual environment and install everything into it. This is helpful, especially for new developers or developers who only want to make an occasional contribution.`}</p>
    <h2>{`Removing formatting from the conversation`}</h2>
    <p>{`Formatting doesn't seem important, and, in a sense, it isn't because the computer doesn't generally care how code is formatted. However, code is written for humans as much as machines, and people do have opinions over how code should be formatted. Sometimes this can result in unhelpful nitpicks on PRs. Other times this can result in engineers agonizing over how best to format some multi-line list comprehension. Neither outcome is great.`}</p>
    <p>{`Fortunately, formatting fights are generally easy to solve. Code formatters like `}<inlineCode parentName="p">{`black`}</inlineCode>{` or `}<inlineCode parentName="p">{`ruff`}</inlineCode>{` make all of the decisions for you. Even if engineers don't like the resulting formatting, it's easy to stop caring so much.`}</p>
    <p>{`We adopted `}<inlineCode parentName="p">{`ruff`}</inlineCode>{` as our formatter of choice. Users can configure their IDEs to use it, and we can enforce formatting through CI checks. When developers create a pull request, we run a check to make sure everything is formatted appropriately. If the check fails, the pull request can't be merged.`}</p>
    <p>{`Adopting an auto-formatter involves running the codebase through the formatter, resulting in many, many changes. After this is done, you can start enforcing checks for every PR.`}</p>
    <p>{`One pitfall is the effect on `}<inlineCode parentName="p">{`git blame`}</inlineCode>{`. If you format an entire codebase, you'll show up on most lines in `}<inlineCode parentName="p">{`git blame`}</inlineCode>{`. You might even show up for years if particular sections of the code don't see much activity! This isn't helpful for others, and it isn't good for you. To resolve this, we auto-formatted the codebase in a single commit, then added the commit to `}<inlineCode parentName="p">{`.git-blame-ignore-revs`}</inlineCode>{`. The file looks something like this:`}</p>
    <pre><code parentName="pre" {...{}}>{`# Auto-format code with ruff
b1460f7fbddf7fde9d7d50114d4e8d46baf30594
`}</code></pre>
    <p>{`Developers can configure their IDEs to automatically format using ruff. We also provide a precommit hook configuration that fixes any violations.`}</p>
    <h2>{`Automatically finding errors`}</h2>
    <p>{`Ruff is also adept at finding and fixing small errors. Consider some code that sets model hyperparameters:`}</p>
    <pre><code parentName="pre" {...{}}>{`model_hyperparameters = {
    "learning_rate": 0.05,
    "n_estimators": 10,
    "subsample": 1.0,
    "min_samples_split": 2,
    "min_samples_leaf": 1,
    "min_weight_fraction_leaf": 0.0,
    "max_depth": 3,
    "min_impurity_decrease": 0.0,
    "min_samples_leaf": 2,
    "max_features": None,
}
`}</code></pre>
    <p>{`Do you see the issue? We set the `}<inlineCode parentName="p">{`min_samples_leaf`}</inlineCode>{` key twice. If somebody wants to change it, they might miss this, then either wonder what's wrong or, worse, totally miss the problem.`}</p>
    <p>{`We can identify and flag these issues with a linter. A linter examines code to find common errors. A number of linters exist for Python, but we went with `}<inlineCode parentName="p">{`ruff`}</inlineCode>{` (yes, it lints as well as formats!). If we run `}<inlineCode parentName="p">{`ruff check`}</inlineCode>{`, we can see the error:`}</p>
    <pre><code parentName="pre" {...{}}>{`F601 Dictionary key literal \`"min_samples_leaf"\` repeated
`}</code></pre>
    <h3>{`Fixing existing errors`}</h3>
    <p>{`When you first run a linter, it may flag a number of violations. You have several options for dealing with these.`}</p>
    <p>{`The first option is to fix all of the issues. If you have a small codebase, or if the violations are relatively simple, this might be the way to go. If you're using ruff, you can run `}<inlineCode parentName="p">{`ruff check --fix`}</inlineCode>{` to fix a number of errors.`}</p>
    <p>{`However, not all errors may be easily fixable, and you might not have the proper context. In the above example, should `}<inlineCode parentName="p">{`min_samples_leaf`}</inlineCode>{` be 1 or 2? One strategy might be to add files with violations to a list of extensions, then tackle these one at a time. This might work, but the approach has some serious shortcomings. First, the offending files may change and get worse over time as they're worked upon. Second, it can be hard to carve out time to tackle each file.`}</p>
    <p>{`A final approach is to ignore individual violations, rather than entire files. Linters let you ignore specific violations. For example, below, we ignore the duplicate keys in our hyperparameter dictionary:`}</p>
    <pre><code parentName="pre" {...{}}>{`model_hyperparameters = {
    "learning_rate": 0.05,
    "n_estimators": 10,
    "subsample": 1.0,
    "min_samples_split": 2,
    "min_samples_leaf": 1,
    "min_weight_fraction_leaf": 0.0,
    "max_depth": 3,
    "min_impurity_decrease": 0.0,
    "min_samples_leaf": 2,  # noqa: F601
    "max_features": None,
}
`}</code></pre>
    <p>{`This provides a way to acknowledge all existing errors, while enforcing linting on all new code. Ruff actually makes this option very easy. You just have to run `}<inlineCode parentName="p">{`ruff check --add-noqa`}</inlineCode>{`.`}</p>
    <p>{`Ideally, developers who touch code will remove existing violations. This might be a long process, but at least new code will comply with linting rules. Of course, you can still resolve all violations in one large PR, or go file by file.`}</p>
    <p>{`The downside here is that your code may now have `}<inlineCode parentName="p">{`# noqa`}</inlineCode>{` comments littered throughout. These comments are ugly. However, if we take the stance that code must lint, then code that doesn't comply is `}<em parentName="p">{`functionally`}</em>{` ugly--so the comments just bring that ugliness to the surface!`}</p>
    <p>{`To ensure comments get cleaned up as code is improved, we enforced ruff's `}<inlineCode parentName="p">{`RUF100`}</inlineCode>{` rule, which flags unused `}<inlineCode parentName="p">{`# noqa`}</inlineCode>{` directives.`}</p>
    <p>{`We run the linter on every pull request, checking for any violations and blocking the pull request if we find any. We also provide a precommit hook configuration.`}</p>
    <h2>{`Testing code to make sure it works`}</h2>
    <p>{`Automated testing is another line of defense beyond linting. While linting can catch subtle bugs or issues by examining code, testing actually runs code and checks the results. It allows developers to catch corner cases and bad assumptions, and it gives developers more confidence when changing existing code.`}</p>
    <p>{`Not long ago, we had few, if any, automated tests in our ML workflow repository. Automatically testing some machine learning code can be difficult, and, besides, at a small scale, manual testing is often good enough.`}</p>
    <p>{`However, certain code might be highly leveraged in the codebase. For example, often we'll want to run an ML workload for each business on Ramp. This is a problem that lends itself well to parallelization across multiple processes, but provisioning hardware for and starting up each process takes time. So, we sometimes divide a large set of businesses into chunks of a given size. This reduces overhead and still allows us to parallelize work. We have a function called `}<inlineCode parentName="p">{`chunk`}</inlineCode>{` that can be used for this. It takes a list of IDs (or anything else), and a maximum chunk size, then return an `}<inlineCode parentName="p">{`Iterator`}</inlineCode>{`:`}</p>
    <pre><code parentName="pre" {...{}}>{`def chunk(xs: list[T], size: int) -> Iterator[T]:
    ...
`}</code></pre>
    <p>{`It's important that this works correctly. It needs to produce chunks consistent with `}<inlineCode parentName="p">{`size`}</inlineCode>{`, and it needs to not drop any IDs. If there are issues, downstream use cases will be negatively affected. Not only does it need to work correctly when originally written, but it also needs to work correctly in the face of any changes. For example, if an engineer notices that the chunking can be optimized, then makes a change, we need to make sure that the results are still correct. Testing can help us out here.`}</p>
    <p>{`We use `}<inlineCode parentName="p">{`pytest`}</inlineCode>{` for testing, since it's widely used and has a rich ecosystem of supporting tools. It's also compatible with Python's built-in `}<inlineCode parentName="p">{`unittest`}</inlineCode>{` framework.`}</p>
    <p>{`Using pytest, we might have a test to make sure that our function preserves all of the input IDs:`}</p>
    <pre><code parentName="pre" {...{}}>{`def test_chunk_preserves_data():
    chunks = chunk([1, 2, 3, 4, 5], 2)
    assert list(itertools.chain(*chunks)) == [1, 2, 3, 4, 5]
`}</code></pre>
    <p>{`Of course, tests aren't free to write. They take time and effort which might otherwise be spent developing new things. Some organizations measure code coverage, or the proportion of lines in the codebase that are covered by tests, and they might insist on a particular level of coverage. This isn't bad, but it's not quite appropriate for our ML workflow use case. Most code exists in individual workflows and models, and if there's a bug, it will probably only affect one particular application. Instead of focusing on broad coverage throughout the codebase, we pay attention to highly leveraged code, like shared utility functions.`}</p>
    <h2>{`Finding problems with type checking`}</h2>
    <p>{`Python has had type annotations for quite a while. Using annotations, you can add hints about what type a particular variable should hold, or what a function should return. For example:`}</p>
    <pre><code parentName="pre" {...{}}>{`def f(a: str, b: int) -> list[str]:
    ...
`}</code></pre>
    <p>{`While type annotations can serve as documentation, they aren't enforced by Python itself. For that, you need a type checker. A few exist. The most well-known is probably `}<inlineCode parentName="p">{`mypy`}</inlineCode>{`, but others like `}<inlineCode parentName="p">{`pyre`}</inlineCode>{` and `}<inlineCode parentName="p">{`pyright`}</inlineCode>{` also exist. There are pros and cons to each, but we adopted `}<inlineCode parentName="p">{`mypy`}</inlineCode>{` simply because it's widely used, both inside of Ramp and in the broader Python ecosystem.`}</p>
    <p>{`In our case, contributors to our machine learning repository often added types to code. However, sometimes these types were subtly wrong, and type annotation adoption was inconsistent, even within the same section of code.`}</p>
    <p>{`You might wonder whether type checking is beneficial for a language like Python. After all, to some people, Python's dynamic nature is part of the charm, and, besides, type annotations can add a lot of clutter. I'm certainly not an extremist here! I don't want to burden developers with extra steps, but type checking is a tool, and long as we're using it as a means to some end, it can be quite useful.`}</p>
    <p>{`Consider a function that queries data from a data warehouse:`}</p>
    <pre><code parentName="pre" {...{}}>{`def query_warehouse(query, is_qa):
    ...
`}</code></pre>
    <p>{`The `}<inlineCode parentName="p">{`is_qa`}</inlineCode>{` parameter indicates whether the query should be run in a QA database or a production database. From its name, it's probably a boolean.`}</p>
    <p>{`Let's say we want to change it to `}<inlineCode parentName="p">{`env`}</inlineCode>{`, for environment:`}</p>
    <pre><code parentName="pre" {...{}}>{`def query_database(query, env):
    ...
`}</code></pre>
    <p>{`One problem is that we don't know what `}<inlineCode parentName="p">{`env`}</inlineCode>{` should be. We can make that clearer with a type annotation:`}</p>
    <pre><code parentName="pre" {...{}}>{`def query_database(query: str, env: Literal["qa", "prod"]) -> None:
    ...
`}</code></pre>
    <p>{`Above, we say that `}<inlineCode parentName="p">{`env`}</inlineCode>{` should be a string that's either "qa" or "prod". We also document that `}<inlineCode parentName="p">{`query`}</inlineCode>{` should be a string and that the function doesn't return anything.`}</p>
    <p>{`However, this is only part of the appeal! A function to query the data warehouse is probably going to be widely used throughout the codebase, with many call sites. When we change the signature, we may break code. In a perfect world, we might have unit tests to catch this. However, remember that unit tests aren't free, and realistically, we're not going to cover all of the call sites with tests.`}</p>
    <p>{`Type checking will actually catch this issue though! This is huge. Developers can confidently make changes—even big, sweeping changes that touch many parts of the codebase. Whole classes of bugs become impossible!`}</p>
    <h3>{`Adding types gradually`}</h3>
    <p>{`Unfortunately, adopting a type checker on an existing code base can be difficult. When we first ran mypy, we found hundreds of errors! Fortunately, we can use the same strategy that we used for enforcing linting: we can add `}<inlineCode parentName="p">{`# type: ignore`}</inlineCode>{` to errors, turn on enforcement, then progressively fix old code.`}</p>
    <p>{`Unlike ruff, mypy doesn't have an option to automatically add `}<inlineCode parentName="p">{`# type: ignore`}</inlineCode>{` to violations, so we wrote a script to automate this. Like `}<inlineCode parentName="p">{`# noqa`}</inlineCode>{`, `}<inlineCode parentName="p">{`# type: ignore`}</inlineCode>{` can also be qualified with specific error codes. We ended up with lots of code like this:`}</p>
    <pre><code parentName="pre" {...{}}>{`def f(a, b, c):  # type: ignore[no-untyped-def]
    ...
`}</code></pre>
    <p>{`Importantly, we also made mypy complain about `}<em parentName="p">{`unused`}</em>{` `}<inlineCode parentName="p">{`# type: ignore`}</inlineCode>{` comments. Sometimes developers will actually fix a problem and not notice. For example, mypy complains if you call an untyped function. We ignored these cases in the initial ignore commit:`}</p>
    <pre><code parentName="pre" {...{}}>{`some_function(1, 2, 3)  # type: ignore[no-untyped-call]
`}</code></pre>
    <p>{`However, if `}<inlineCode parentName="p">{`some_function`}</inlineCode>{` suddenly receives type annotations, the error no longer applies, and the call is fine. The comment should reflect this. Enforcing this is just a matter of adding a line to the configuration:`}</p>
    <pre><code parentName="pre" {...{}}>{`warn_unused_ignores = true
`}</code></pre>
    <h3>{`Configuring mypy`}</h3>
    <p>{`By default, mypy is rather permissive. We added extra configuration to disallow defining and calling untyped functions, and to have mypy check untyped functions, rather than ignoring them:`}</p>
    <pre><code parentName="pre" {...{}}>{`disallow_untyped_calls = true
disallow_untyped_defs = true
disallow_incomplete_defs = true
check_untyped_defs = true
disallow_untyped_decorators = true
`}</code></pre>
    <p>{`Unfortunately, not all libraries provide type annotations. We instructed mypy to ignore most of these with a configuration section:`}</p>
    <pre><code parentName="pre" {...{}}>{`[[tool.mypy.overrides]]
module = [
  "boto3",
  "catboost",
]
ignore_missing_imports = true
`}</code></pre>
    <p>{`For some critical libraries though, we generated or wrote type stubs. We didn't aim for completeness here but instead quickly wrote some partial stubs to cover most of our use.`}</p>
    <h2>{`The future`}</h2>
    <p>{`With the above improvements, we were able to get our codebase in good shape—and keep it there. However, quality itself isn't the end goal. Code is a means to an end. More importantly, we were able to encourage faster iteration with greater confidence. Engineers and data scientists can make changes and ship new functionality, no matter their experience.`}</p>
    <p>{`Of course, the journey isn't over. As Ramp continues to grow, we'll hit new challenges, and maintaining and even improving our velocity will require new solutions. For this particular ML codebase, we're looking at ways to further shift burdens from contributors to tooling.`}</p>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;