
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import { RyuImage, RyuTextLabel } from '@ramp/ryu'
import nathan from './nathan.png'
import nyc from './nyc.jpeg'
export const meta = {
  date: '2021-10-28T17:00:00.000Z',
  title: 'Lessons from my internship at Ramp',
  description: 'The Ramp internship experience, from a former intern',
  authors: [{
    name: 'Nathan H. Leung',
    twitter: '@nathanhleung',
    website: 'https://www.natecation.com',
    photo: nathan
  }]
};

const layoutProps = {
  meta
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`The life of an intern can be an interesting and rewarding journey. For a few months, you’re given a front-row seat to a company that you’re really excited about and, eventually, hope to work for. This time can be full of learning experiences and plenty of opportunities to grow your network and expertise. Thankfully, I had this opportunity just a few months ago here at Ramp.`}</p>
    <p>{`From March to June of this year, I `}<a parentName="p" {...{
        "href": "https://www.natecation.com/trips/"
      }}>{`lived`}</a>{` in New York City
and joined the team as a software engineering intern—I was the ninth intern overall.
During those three months, dollars spent on Ramp cards doubled, headcount grew by 30, and the company `}<a parentName="p" {...{
        "href": "https://techcrunch.com/2021/04/08/spend-management-startup-ramp-confirms-115m-raise-1-6b-valuation/"
      }}>{`became a unicorn`}</a>{`.
In other words, it quickly became the startup to work for.`}</p>
    <p>{`Like I said before, internships can be full of incredible teaching moments,
and my three months as an intern were just that. Here are just a few of the many things
I learned in those exciting months.`}</p>
    <h2>{`Execution as a competitive advantage`}</h2>
    <p><em parentName="p">{`"Ideas are worth nothing unless executed."`}</em></p>
    <p>{`– Steve Jobs`}</p>
    <p>{`On the surface, Ramp's primary offering — a charge card for businesses with a dynamic limit based on cash balances that doesn't require a founder guarantee—is a commodity. There were several modern offerings on the market, even before Ramp was founded. So going in, I wondered: since Ramp is one of the most recent players in this space and is going up against titans in the industry, does it have any chances of success?
I quickly found out that the answer to this question is a resounding yes.`}</p>
    <p>{`There are a lot of things that make Ramp special, but simply put, we have some of the best execution in the game.
As an intern, I spent most of my time with the engineering and product teams.
Working with them, I was blown away not only by the team's amazing technical ability (lots of people that could have been doing quant trading or building self-driving cars instead),
but also the great culture.
Ramp has built a culture that has a bias for shipping fast while still staying focused and building the right things.
And so, despite being new on the scene, Ramp was able to exceed the competition by delivering better value for the customer through new technology and a differentiated product.`}</p>
    <p>{`Ramp also has incredible execution outside of product and engineering as well. Alex Song, our Head of Finance, brings years of experience in the credit and lending markets. Through his network and expertise Ramp was able to `}<a parentName="p" {...{
        "href": "https://ramp.com/blog/how-ramp-raised-150m-in-debt-financing-from-goldman-sachs"
      }}>{`obtain capital`}</a>{` at favorable terms from a reputable lender, Goldman Sachs.`}</p>
    <p>{`This is what makes Ramp so different. With so many smart and talented people across teams, Ramp is able to execute exceptionally well and turn a commodity into a unique and marketable product.`}</p>
    <p>{`In my own life, when I previously thought of personal app ideas,
I'd be disappointed when I saw that someone else was already doing the same thing.
Now, thanks to my experience at Ramp, I think of all the ways I can out-execute them.`}</p>
    <h2>{`Choose the Right Company`}</h2>
    <p><em parentName="p">{`“If you're offered a seat on a rocket ship, don't ask what seat! Just get on.”`}</em></p>
    <p>{`– Sheryl Sandberg`}</p>
    <p>{`Early in my engineering career, I interned at Google. For those wondering what that was like, picture a college computer science class where the students actually get individualized attention. These were very formal programs with strong mentorship, and clear project scopes—but were low-stakes projects. I think programs like these are great for turning inexperienced students into solid software engineers. But the impact of my work was difficult to measure.`}</p>
    <p>{`After that, I worked for a small startup in San Francisco. The startup was founded in April 2019 and went through Y Combinator's Summer 2019 batch along with hundreds of other companies founded around the same time.`}</p>
    <p>{`Since Ramp was started in March 2019, around the same time as that YC startup, it would’ve made sense for the experience to be fairly similar. Ramp, however, was in a completely different stage of growth. Its revenue, headcount and growth rate were all several times larger than almost all of those YC 2019 companies.`}</p>
    <p>{`Fast-growing companies certainly aren’t for everyone. If you’re inexperienced, the high speed and high stakes (and inevitable lack of documentation) can be challenging. I personally loved the flexibility of Ramp’s internship: I was able to work with different teams and see the many parts of the engineering org. While the fast pace did feel daunting sometimes, Ramp set me up with great mentorship, so the combination of that and my prior experience was enough to ensure I succeeded.`}</p>
    <p>{`For me, witnessing Ramp's amazing trajectory made it clear that working on `}<em parentName="p">{`big problems`}</em>{` at a `}<em parentName="p">{`fast-growing company`}</em>{` is one of the biggest levers I have to magnify my impact 100 or even 1000x.`}</p>
    <h2>{`San Francisco vs. New York?`}</h2>
    <p><em parentName="p">{`"Live in New York City once but leave before it makes you hard. Live in northern California once but leave before it makes you soft."`}</em></p>
    <p>{`— `}<a parentName="p" {...{
        "href": "https://www.chicagotribune.com/columns/chi-schmich-sunscreen-column-column.html"
      }}>{`Mary Schmich`}</a></p>
    <RyuImage src={nyc} mdxType="RyuImage" />
    <p>{`One reason Ramp caught my eye when I was originally looking for internships was that the company was based in New York City, a place I'd never lived in before. At the time, I wanted to experience as many different cities as I could, so the opportunity to work in New York was a big draw.`}</p>
    <p>{`I found a bedroom in the East Village and stayed there for most of my internship, and spent my last few weeks in the city on an air mattress in Soho with my good friend and coworker `}<a parentName="p" {...{
        "href": "https://twitter.com/Pavel_Asparagus"
      }}>{`Pavel`}</a>{`.`}</p>
    <p>{`The first thing I noticed about New York is that it has a lot of things going on beyond tech. I’d previously spent time in San Francisco, a place dominated by startups to the extent that it’s hard to meet people who aren’t in tech. In New York, by contrast, tech felt like just a part of the city, and the community felt tighter-knit as a result. I'd often recognize folks at different events around New York, whereas in San Francisco I'd see someone walking around wearing a YC hoodie but I usually wouldn't recognize them. Aside from just the tech scene, I loved the energy of the city and I was generally just excited to have new experiences in a different city.`}</p>
    <p>{`That said, rather than coming to any "conclusions" about the supremacy of San Francisco or New York, my main takeaway is that I want to be able to continue enjoying both cities. Luckily, Ramp is hiring everywhere—with employees in San Francisco, New York, Miami, and beyond (including Latin America, Europe and the Middle East)—so it looks like alternating coasts can be a real possibility for me.`}</p>
    <h2>{`Join the Team`}</h2>
    <p>{`If what's written above sounds interesting to you, and you'd like to jump onto a rocketship with an absolutely stellar crew (whether as an intern or full-time employee), apply to join here: `}<a parentName="p" {...{
        "href": "https://ramp.com/careers"
      }}>{`https://ramp.com/careers`}</a>{`.`}</p>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;