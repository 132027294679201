export const BLOG_HOME_BREAKPOINTS = {
  tablet: '769px',
  desktop: '1080px',
}

export const HOME_BLOG_CARD_HEIGHT = 440

export const BLOG_CONTENT_MAX_WIDTH = 1168

export const PRIMARY_COLOR = '#0C0A08'

export const RYU_SPACING = {
  1: '0.25rem', // 4px
  2: '0.5rem', // 8px
  3: '0.75rem', // 12px
  4: '1rem', // 16px
  5: '1.25rem', // 20px
  6: '1.5rem', // 24px
  7: '1.75rem', // 28px
  8: '2rem', // 32px
  9: '2.25rem', // 36px
  10: '2.5rem', // 40px
  11: '2.75rem', // 44px
  12: '3rem', // 48px
  13: '3.25rem', // 52px
  14: '3.5rem', // 56px
  15: '3.75rem', // 60px
}
