
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import { RyuImage, RyuFlex } from '@ramp/ryu'
import desired_state from './desired_state.jpg'
import current_state from './current_state.jpg'
import kodiak from './kodiak.png'
import harry_first_attempt from './harry_first_attempt.png'
import pangaea from './pangaea.png'
import stone_age from './stone_age.png'
export const meta = {
  date: '2022-03-22T17:00:00.000Z',
  title: 'Faster Pull Request Merges',
  description: 'Unblocking engineers by letting them merge their code 5x faster.',
  authors: [{
    name: 'Neal Wu & Young Kim',
    website: '',
    twitter: '',
    position: 'Engineering',
    bio: ''
  }]
};

const layoutProps = {
  meta
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`One of our keys to success at Ramp is empowering engineers to iterate and get changes into production quickly. We do this while ensuring that newly committed code is safe to deploy by maintaining a set of Python unit / integration tests.`}</p>
    <p>{`Over time, this test suite has grown larger and larger, now reaching over 12 minutes per test run. Even worse, engineers would sometimes have to wait up to 1-2 hours between queueing their code for merge and actually seeing it in production.`}</p>
    <p>{`How can we make this process faster for engineers, allowing them to deploy their code faster?`}</p>
    <h3>{`The world before`}</h3>
    <RyuFlex justifyContent='center' mdxType="RyuFlex">
  <RyuImage src={pangaea} mdxType="RyuImage" />
  <RyuImage src={stone_age} mdxType="RyuImage" />
    </RyuFlex>
    <p>{`The diagram below shows the prior state of how we merged pull requests at Ramp.
Code had to be up-to-date with `}<inlineCode parentName="p">{`master`}</inlineCode>{` and pass status checks such as linting and tests before merging.
We used `}<a parentName="p" {...{
        "href": "https://kodiakhq.com/"
      }}>{`kodiak`}</a>{` to help merge PRs, which would keep track of a given PR’s spot in the merge queue, auto-update it, and auto-merge once it reached the front of the queue.`}</p>
    <RyuImage src={current_state} mdxType="RyuImage" />
    <RyuImage src={kodiak} mdxType="RyuImage" />
    <p>{`The upshot of this was that every new PR merged would make all other PRs out of date with `}<inlineCode parentName="p">{`master`}</inlineCode>{`. This meant that every single PR required a full new run of our test suite from scratch.`}</p>
    <p>{`Although this was a reasonable requirement when test suite times were low, our codebase had a growing `}<inlineCode parentName="p">{`pytest`}</inlineCode>{` suite that took 12 minutes to run. And as the size of the engineering team grew, the queue of pull requests waiting to get merged increased as well, with the average pull request waiting over an hour (12 minutes * 5 PRs in the queue) to be merged.`}</p>
    <p>{`Our goal: eliminate this merge queue to unblock engineers and help them ship code faster.`}</p>
    <h2>{`First Attempt`}</h2>
    <RyuImage src={desired_state} mdxType="RyuImage" />
    <p>{`Our first attempt is illustrated by the diagram above. We would run tests on PRs as we already were doing, and as long as they passed, engineers could merge into `}<inlineCode parentName="p">{`master`}</inlineCode>{` in parallel without having to rerun tests.`}</p>
    <p>{`Our thought process was that most engineers’ code doesn’t overlap with other engineers’ changes. For example, someone working on Travel shouldn’t prevent someone else from pushing a change to Bill Pay. And engineers working on overlapping features would almost always get an explicit merge conflict.`}</p>
    <p>{`However, we still wanted to consider the possibility that two branches that passed tests locally could fail tests on `}<inlineCode parentName="p">{`master`}</inlineCode>{` after their changesets were merged. `}</p>
    <p>{`To prevent this we added the requirement that before any deployment, tests would need to pass on `}<inlineCode parentName="p">{`master`}</inlineCode>{`.`}</p>
    <p>{`After making these changes to the deploy pipeline, we removed the requirement that “Pull requests are up-to-date with master.”`}</p>
    <p>{`Unfortunately, the following happened:`}</p>
    <RyuImage src={harry_first_attempt} mdxType="RyuImage" />
    <p>{`Our python stack uses `}<inlineCode parentName="p">{`Flask`}</inlineCode>{` + `}<inlineCode parentName="p">{`SQLAlchemy`}</inlineCode>{`, with the following dependencies:`}</p>
    <pre><code parentName="pre" {...{}}>{`alembic~=1.7.5
Flask~=1.1.1
Flask-Migrate~=2.6.0
Flask-SQLAlchemy~=2.4.1
sqlalchemy~=1.4.36
`}</code></pre>
    <p>{`We use `}<a parentName="p" {...{
        "href": "https://alembic.sqlalchemy.org/en/latest/"
      }}>{`alembic`}</a>{` to manage database migrations. Each database migration generates a file in `}<inlineCode parentName="p">{`migrations/versions`}</inlineCode>{` that looks like the following:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-python"
      }}>{`# revision identifiers, used by Alembic.
revision = "37596116ac17"
down_revision = "a6b00f131756"

def upgrade():
    op.create_table(...)

def downgrade():
    op.drop_table(...)
`}</code></pre>
    <p>{`If two developers branched off the same revision and generated different migrations, each of their local changes would pass tests. `}</p>
    <pre><code parentName="pre" {...{
        "className": "language-python"
      }}>{`# Developer A
revision = "37596116ac17"
down_revision = "a6b00f131756"

def upgrade():
    op.create_table("table_A", ...)

def downgrade():
    op.drop_table("table_A", ...)
`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "language-python"
      }}>{`# Developer B
revision = "9d37dc60b812"
down_revision = "a6b00f131756"

def upgrade():
    op.create_table("table_B", ...)

def downgrade():
    op.drop_table("table_B", ...)
`}</code></pre>
    <p>{`However, if both of these migrations were merged into `}<inlineCode parentName="p">{`master`}</inlineCode>{`, from revision `}<inlineCode parentName="p">{`a6b00f131756`}</inlineCode>{`,  we would get the following error:`}</p>
    <pre><code parentName="pre" {...{}}>{`Only a single head is supported. The script directory has multiple heads (due to branching), which must be resolved by manually editing the revision files to form a linear sequence.
Run \`alembic branches\` to see the divergence(s).
`}</code></pre>
    <p>{`This is because there would be “multiple heads”, or two DB migrations that would cause alembic to be unable to determine the current head.`}</p>
    <p>{`We can illustrate this further with the diagram below:`}</p>
    <pre><code parentName="pre" {...{}}>{`# Pull Request A
┌────────────────────────┐      ┌───────────────────────┐
│        master          │─────▶│       revision A      │
│      a6b00f131756      │      │      37596116ac17     │
└────────────────────────┘      └───────────────────────┘

# Pull Request B
┌────────────────────────┐      ┌───────────────────────┐
│        master          │─────▶│       revision B      │
│      a6b00f131756      │      │      9d37dc60b812     │
└────────────────────────┘      └───────────────────────┘

# Merged (multiple heads!)
┌────────────────────────┐      ┌───────────────────────┐
│        master          │─────▶│      revision A       │
│      a6b00f131756      │      │     37596116ac17      │
└────────────────────────┘      └───────────────────────┘
           │                    ┌───────────────────────┐
           │                    │      revision B       │
           └───────────────────▶│     9d37dc60b812      │
                                └───────────────────────┘
                                
                                
`}</code></pre>
    <p>{`We needed to prevent these cases from happening to allow out-of-date merges into `}<inlineCode parentName="p">{`master`}</inlineCode>{`.`}</p>
    <h2>{`Solution`}</h2>
    <p>{`We realized that pull requests without DB migrations should be able to merge freely, while migration pull requests should be required to be up-to-date with `}<inlineCode parentName="p">{`master`}</inlineCode>{`.`}</p>
    <p>{`Our first idea was to set up a custom GitHub action to enforce this. However, we found that there was no straightforward way to re-run checks every time `}<inlineCode parentName="p">{`master`}</inlineCode>{` was updated. `}</p>
    <p>{`What if we could take advantage of merge conflicts to prevent pull requests from merging?`}</p>
    <p>{`We decided to add a new file in the repo, `}<inlineCode parentName="p">{`migrations/migration-hash.txt`}</inlineCode>{`, that contained a hash of all the filenames in `}<inlineCode parentName="p">{`migrations/versions`}</inlineCode>{`.
Each time an engineer added a new migration, the hash would change, causing GitHub to have a merge conflict on `}<inlineCode parentName="p">{`migration-hash.txt`}</inlineCode>{`.`}</p>
    <p>{`Here's the commands we used to generate the hash, and the file, respectively:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`generate-migration-hash:
    # We're using python3 rather than a *nix command intentionally, since developers
    # are on different operating systems.
    @ls migrations/versions/*.py | sort \\
            | python3 -c 'import hashlib; import sys; print(hashlib.md5(sys.stdin.read().encode("utf-8")).hexdigest())'

generate-migration-hash-file:
    @make generate-migration-hash > migrations/migration-hash.txt
`}</code></pre>
    <p>{`The diagram below shows how `}<inlineCode parentName="p">{`migration-hash.txt`}</inlineCode>{` would change as pull requests were merged in:`}</p>
    <pre><code parentName="pre" {...{}}>{`                     master
                    /      \\
                   /        \\
                  /          \\
                 /            \\
     migration A               migration B
     hash(master + A)          hash(master + B)
                 \\            /
                  \\          /
                   \\        /
                    \\      /
                master -> A -> B
                hash(master + A + B)
`}</code></pre>
    <p>{`Note that:`}</p>
    <ul>
      <li parentName="ul">{`Pull requests that don’t contain merges will never be blocked by a merge conflict, as they don’t modify `}<inlineCode parentName="li">{`migration-hash.txt`}</inlineCode>{`.`}</li>
      <li parentName="ul">{`Pull requests that contain migrations will only be blocked by other pull requests that contain migrations, and are otherwise not required to be up-to-date with `}<inlineCode parentName="li">{`master`}</inlineCode>{`.`}</li>
    </ul>
    <p>{`With this change, we were able to modify our deploy pipeline to look like the desired state:`}</p>
    <RyuImage src={desired_state} mdxType="RyuImage" />
    <p>{`The average time to merge decreased from 1+ hour to 12 minutes (the time it takes for our test suite to run).`}</p>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;