
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const meta = {
  date: '2023-11-11T17:00:00.000Z',
  title: 'Engineering Management',
  description: 'Reflections on creating and scaling the Core Engineering team ',
  authors: [{
    name: 'Patrick Anderson',
    website: 'https://patrick.vc',
    position: 'Head of Core Engineering'
  }]
};

const layoutProps = {
  meta
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`Much has been said about Ramp's culture of velocity. The engineering team here has become a formidable force and a major driver of this culture. Uniquely special and worthwhile things were achieved here over the past few years and I'll forever be grateful to have been a part of that. For the sake of posterity and completeness it feels important to add an engineering leadership perspective to the corpus of public commentary. The benefit of this dawned on me after I created a LinkedIn profile to be included in our recruiter outreach materials. Something unexpected happened - I received many cold reach outs from founders of companies I admired asking to chat about how Ramp built an engineering team with a reputation for blistering pace of execution. After several of these conversations, some common themes emerged and I'd like to share them here.`}</p>
    <p>{`It's nearly impossible to give useful generic advice. This isn't intended to be that. This is a brief survey of what worked as I incepted and grew Core Engineering, Ramp's largest engineering team in terms of both headcount and scope of ownership, from 0 to 50+ engineers. It should also be noted that some of this advice may start to exhibit stress fractures as headcount scales further. Any advice contained in these reflections should be understood in the context of a company with a headcount under `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Dunbar%27s_number"
      }}>{`Dunbar's number`}</a>{`.`}</p>
    <h2>{`Operative Definition`}</h2>
    <p>{`Over many cycles of onboarding and mentoring new engineers, the definition I settled on for the role of an engineering manager is threefold:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`A manager should be able to unblock their reports, ensuring they're never idle. If your report is idling they're likely not learning and the business is not receiving value. Blockers come in many forms - a tar pit of technical debt, cross-functional team dependencies, company roadmap ambiguity - just to name a few. A manager should be able to help their report navigate all of these and ensure their report is always spending time on things that move the needle for the business and grow their report's abilities as an engineer.`}</p>
        <br />
      </li>
      <li parentName="ul">
        <p parentName="li">{`They should appreciate the contributions of their direct report. It is not meaningful to do this in a shallow manner - with generic positive comments and a high five. This will require technical depth, sufficient attention, and context on the project. The feeling of receiving close attention to your work is inimitable and it produces many positive effects for the report and the manager.`}</p>
        <br />
      </li>
      <li parentName="ul">
        <p parentName="li">{`They should help their report ascend the leveling deck. Assuming that the company's leveling deck is meaningful and aligns the contributions of each engineer to the goals of the company, it is in the best interest of all parties involved for the report to ascend to the next level or excel in their current role. The report gains a track record of meaningful accomplishments and levels up professionally, the manager gains a more capable team member, and the company meets its goals. Helping a report ascend the leveling deck involves tailoring the report's workload to close gaps in the enumerated criteria for success and teeing up enough opportunities for shots on goal to demonstrate proficiency.`}</p>
      </li>
    </ul>
    <h2>{`Pay Attention`}</h2>
    <p>{`If I had to boil down all of the advice in this post to two words, they would be "pay attention."`}</p>
    <p>{`Relative to how much you hear from others, you need to say very little as a leader - what little you do say should be consistent across time and audience, actionable, and repeated ad nauseum; but, above all else you need to notice and take interest in as much detail as possible.`}</p>
    <p>{`Over time a team will reflect the compounded long term memory, short term attention span, and mental acuity of its leader - ingesting as much information as possible, then compressing it into a coherent mental model and taking action when needed. Do everything in your power to increase your ability to pay attention. Meditate, eat well, sleep enough, exercise regularly, and guard against cognitive distortions.`}</p>
    <p>{`When headcount is under Dunbar's number, I've found it's possible to build a robust mental model of your organization such that it can be understood as a deterministic system. With a high fidelity understanding of such a system, it's possible to make precise and quick changes that work as intended. If you're no longer able to pay enough attention to build such a mental model, your organization risks resembling a complex system in the biological sense. Biological systems must evolve to change and that happens over long time scales with often unexpected results.`}</p>
    <p>{`Paying attention will allow you to be consistent. The constituent parts of an organization require consistency of direction to ensure they are working towards a common goal. In the same way that a database would be worthless if it returned different results when queried from different connections, a leader will lose the trust of their organization if the team learns different members get different answers to the same question in a short space of time. In my experience, this inconsistency emanates most frequently from inattention - one off pieces of guidance are deployed without accounting for how they will form a coherent whole. It's critical to pay enough attention so as not to introduce contradictions. Just as you are building a mental model of the organization, so are your reports. Your reports will benefit from an example of consistent reasoning so they're able to back out the logic being applied and orient themselves uniformly.`}</p>
    <p>{`Make sure people aren't working on anything that doesn't matter to the business - your report should never get to the end of their project only to discover that it doesn't matter to the business. Often times useless projects are the result of an artificial constraint - such as an infrastructure configuration - being imposed and then subsequently evaporating after some time. A manager should strive to have enough organizational context to think probabilistically about this outcome.`}</p>
    <p>{`Paying sufficient attention should mean you understand the motivations of your direct reports. Ask yourself "why would anyone care to spend their valuable life time working with me?" and the answer can't be "because I hope they do and I think they should." Really put in effort to understand their core motivations and then genuinely seek to align their role to those. If you fail to do this, "mysterious" attrition will occur with the passage of time.`}</p>
    <p>{`Paying attention is also about spotting suboptimal tradeoffs. Too often 'corporate feedback' processes - especially under time pressure - reduce to managers spotting the short leg of a tradeoff and pointing it out. For example, "you could've spent more time on this spec" while lacking awareness of the existential bug they opted to remedy instead. Failing to spot optimal tradeoffs has a twofold negative impact - the first damaging result is you train your direct report to make the suboptimal tradeoff in the future, the second is that you signal to them that you didn't even notice the greater success they achieved as a result of their tradeoff. Be very certain your feedback is backed by a sufficient amount of attention so as not to fall into this trap - usually this will mean you start by asking your report questions to better understand something you initially perceive to be suboptimal.`}</p>
    <h2>{`Technical Depth`}</h2>
    <p>{`In April of 2021 I circulated an internal memo titled "Against Engineering Managers." This came in response to a batch of non-technical professional managers I interviewed who lacked any observable hard skills. Core Engineering found success in choosing to only hire individual contributor engineers. Some of these engineers migrated into management positions very gradually after observing their ability to meet my "operative definition" of a manager.`}</p>
    <p>{`Managers with hard technical skills enjoy a meaningful ability to appreciate their report's contributions. Those lacking these skills are often reduced to managing by heuristic. Without sufficient technical depth, it's impossible to tell who's achieving velocity while making optimal tradeoffs versus those achieving it via `}<a parentName="p" {...{
        "href": "https://cs.fit.edu/~kgallagher/Schtick/How%20To%20Write%20Unmaintainable%20Code.html"
      }}>{`writing unmaintainable code`}</a>{` and engaging in "happy path engineering." Are team members visiting your report's desk to speak to them because they're especially valuable or because they've become an artificial bottleneck? A lack of hard skills makes this almost impossible to answer. Appreciation of contributions in depth allows you to reward outputs, not inputs. Your report sitting at their desk until late at night is not business impact. Their inputs need to make contact with reality at some point.`}</p>
    <p>{`I've observed that managers who possess relevant hard skills are far less likely to fall victim to the `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/McNamara_fallacy"
      }}>{`McNamara fallacy`}</a>{`. They can map out the journey of their reports as they complete a project, appreciating even the parts which are hard to metricize.`}</p>
    <p>{`Collecting signal about your organization's output without generating work for your reports is greatly enhanced by the possession of hard skills. A manager skilled in this way can gain a deep understanding through conversations and code reviews instead of commissioning status update reports. It's worth noting here that doing a good job is one task for your report and too often showcasing their work for broad appreciation is another - I've noticed over the course of my career that especially at large companies, some employees fall into the trap of only doing the latter. A good manager should appreciate contributions such that the need to showcase is minimal - that's very hard to do if your report is significantly more technically adept than you.`}</p>
    <p>{`Do not allow your hard skills to atrophy. Not only will it hinder your abilities as a manager of engineers, it will also risk a loss of empathy as you no longer feel the CI/CD pipeline become slow or your map of technical debt tar pits fades.`}</p>
    <h2>{`Compounding`}</h2>
    <p>{`In my experience, high performance teams are built slowly through compounded and often subtle good decisions.`}</p>
    <p>{`For example, I tested each manager's aptitude over a monthslong period of time and almost always via mentorship of an intern. If things began to go poorly, the intern mentor's manager could quickly take the reigns and navigate the remaining, finite internship time to a smooth conclusion. No reporting changes necessary.`}</p>
    <p>{`Growing the organization slowly in this way allows the best managers to train their direct reports to then incept their own organization and a fractal branching structure of management competence emerges. Conversely, if headcount swells abruptly, it's very hard to tell who will work well together and which reporting lines make the most sense. In extreme cases - as when headcount doubles instantly - it's a toss up if the existing company culture or the culture represented by the equal number of new joiners will emerge as dominant.`}</p>
    <p>{`On a cautionary note, compounding works in both directions and it's very hard to recover from a long stretch of time that's passed during which inertia and inattention drove the compounding of bad decisions. For example, disputes among team members should be adjudicated as soon as possible - if allowed to fester, human memory will compress the fact patterns surrounding them into negative sentiment and it will be hard to remedy this once the facts are lost to time. Attempt to resolve the dispute quickly through reasoned conversation or separate the team members into swim lanes where they gel better with their peers.`}</p>
    <h2>{`One-on-ones`}</h2>
    <p>{`The importance of one-on-ones is mental model building. If you front load understanding the perspectives of your direct reports, team members, and stakeholders, you will be able to apply it asynchronously when time is of the essence and it's not possible to check with each one of them in advance of rendering a decision. One-on-ones are the primary means by which you build the high fidelity mental model of the organization - allowing for the application of changes in a deterministic way.`}</p>
    <p>{`Frequent one-on-one communication yields the additional benefit of changing the default assumption to one of best intent. Your interlocutors are able to appreciate the constraints you work with and charitably understand your approach to problem solving, without having to speculate about your motives. I've noticed that the more you give people the opportunity to talk to you, the less they will talk about you or your decisions in a way that lacks context and yields fruitless speculation.`}</p>
    <p>{`Another benefit of one-on-one communication is the propagation of narratives in a high fidelity manner, accompanied by relevant context often in response to real time feedback. Speaking individually to a significant subsection of an organization to frame topics from your perspective is a powerful way to lead to a desired outcome. A deep exploration of this dynamic is available `}<a parentName="p" {...{
        "href": "https://www.niallferguson.com/the-square-and-the-tower"
      }}>{`from Niall Ferguson in "The Square and the Tower"`}</a></p>
    <h2>{`Acknowledgements`}</h2>
    <p>{`I owe an immense debt of gratitude to my current and former Core Engineering team members `}<a parentName="p" {...{
        "href": "https://www.linkedin.com/in/pavel-asparouhov/"
      }}>{`Pavel Asparouhov`}</a>{`, `}<a parentName="p" {...{
        "href": "https://www.linkedin.com/in/shreyan-jain/"
      }}>{`Shreyan Jain`}</a>{`, `}<a parentName="p" {...{
        "href": "https://www.linkedin.com/in/rodda-john/"
      }}>{`Rodda John`}</a>{`, `}<a parentName="p" {...{
        "href": "https://www.linkedin.com/in/jared-wasserman/"
      }}>{`Jared Wasserman`}</a>{`, `}<a parentName="p" {...{
        "href": "https://www.linkedin.com/in/hledley/"
      }}>{`Harry Ledley`}</a>{`, `}<a parentName="p" {...{
        "href": "https://www.linkedin.com/in/sergenasr/"
      }}>{`Serge Nasr`}</a>{`, `}<a parentName="p" {...{
        "href": "https://www.linkedin.com/in/jiajun-xu-4615b2170/"
      }}>{`Jiajun Xu`}</a>{`, `}<a parentName="p" {...{
        "href": "https://www.linkedin.com/in/ariel-langer-3bbb13152/"
      }}>{`Ariel Langer`}</a>{`, `}<a parentName="p" {...{
        "href": "https://www.linkedin.com/in/max-lahey-shoov-645b7019/"
      }}>{`Max Lahey`}</a>{`, `}<a parentName="p" {...{
        "href": "https://www.linkedin.com/in/yuandaviddeng/"
      }}>{`David Deng`}</a>{`, `}<a parentName="p" {...{
        "href": "https://www.linkedin.com/in/claraduffy/"
      }}>{`Clara Duffy`}</a>{`, `}<a parentName="p" {...{
        "href": "https://www.linkedin.com/in/mattdodge/"
      }}>{`Matt Dodge`}</a>{`, `}<a parentName="p" {...{
        "href": "https://www.linkedin.com/in/lawrence-li-203300236/"
      }}>{`Lawrence Li`}</a>{`, `}<a parentName="p" {...{
        "href": "https://www.linkedin.com/in/jack-mastrangelo/"
      }}>{`Jack Mastrangelo`}</a>{`, `}<a parentName="p" {...{
        "href": "https://www.linkedin.com/in/anishravipati/"
      }}>{`Anish Ravipati`}</a>{`, `}<a parentName="p" {...{
        "href": "https://www.linkedin.com/in/krishdholakiya/"
      }}>{`Krish Dholakiya`}</a>{`, `}<a parentName="p" {...{
        "href": "https://www.linkedin.com/in/ross-aiken-a0657933/"
      }}>{`Ross Aiken`}</a>{`, and Dylan Mann. They did an exceptional job of managing engineers while also acting as formidable individual contributors in their own right. I learned more than I could have imagined over the past four years and it was possible in large part due to your excellence as peers.`}</p>
    <p>{`Of the tens of books I've read on management, `}<a parentName="p" {...{
        "href": "https://www.oreilly.com/library/view/the-managers-path/9781491973882/"
      }}>{`The Manager's Path by Camille Fournier`}</a>{` stood out to me as singularly valuable and crystallized many of my disparate learnings into a coherent structure. I'd recommend it to anyone interested in leading a team of engineers.`}</p>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;