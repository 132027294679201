import styled from 'styled-components'
import backgroundGrid from '~/src/assets/background-grid.png'

export const GraphPaper = styled.div`
  background-color: white;
  background-image: url(${backgroundGrid});
  background-repeat: repeat;
  width: 100%;
  height: 100%;
`
