import React from 'react'
import { RyuFlex } from '@ramp/ryu/lib/components/RyuFlex'
import styled from 'styled-components'
import { HoverRevealText } from '../../../components/RevealText'
import { GrungeHighlight } from '~/src/components/GrungeHighlight'
import { BlogShowOnDesktop } from '../../../components/BlogShowOnDesktop'
import { BlogShowOnMobile } from '../../../components/BlogShowOnMobile'
import { BLOG_HOME_BREAKPOINTS, RYU_SPACING } from '~/src/styles/constants'

const LOCATION_TEXT = 'NYC / Miami / SF'

export function BlogTitle() {
  return (
    <RyuFlex justifyContent='center' direction='column' alignItems='center' marginSize='m'>
      <LogoHeading>
        <span>Ramp</span>
        <GrungeHighlight>Engineering</GrungeHighlight>
      </LogoHeading>
      <LocationHeading>
        <BlogShowOnDesktop>
          <HoverRevealText text={LOCATION_TEXT} />
        </BlogShowOnDesktop>
        <BlogShowOnMobile>{LOCATION_TEXT}</BlogShowOnMobile>
      </LocationHeading>
    </RyuFlex>
  )
}

const LocationHeading = styled.span`
  font-family: Menlo;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 115%;
`

const LogoHeading = styled.h1`
  font-family: Menlo;
  font-style: normal;
  font-weight: 400;
  line-height: 115%;
  letter-spacing: -0.96px;
  text-transform: uppercase;
  text-align: center;
  font-size: 32px;

  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${RYU_SPACING[3]};

  @media (min-width: ${BLOG_HOME_BREAKPOINTS.tablet}) {
    font-size: 48px;
  }
`
