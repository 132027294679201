import React, { FunctionComponent } from 'react'
import { RyuBreak, RyuLink, withRyuErrorBoundary } from '@ramp/ryu'
import styled from 'styled-components'

import { RegularPageContainer } from '~/src/components/RegularPageContainer'
import { TOS_URL } from '~/src/urls'
import { ReceiptBody } from '~/src/components/ReceiptCard'

export const BlogFooter: FunctionComponent = withRyuErrorBoundary(() => {
  return (
    <BlogFooterRoot>
      <RyuBreak size='xl' />
      <RyuBreak size='xl' />
      <RegularPageContainer>
        <ReceiptBody.p size='s' color='hushed' uppercase={true} textAlign='center'>
          © {new Date().getFullYear()} Ramp Business Corporation. “Ramp,” "Ramp Financial" and the Ramp
          logo are trademarks of the company.
          <RyuBreak />
          The Ramp Visa Commercial Card and the Ramp Visa Corporate Card are issued by Sutton Bank and
          Celtic Bank (Members FDIC), respectively. Please visit our{' '}
          <RyuLink href={TOS_URL} externalLinkIcon={false}>
            Terms of Service
          </RyuLink>{' '}
          for more details.
        </ReceiptBody.p>
      </RegularPageContainer>
      <RyuBreak size='xxl' />
      <RyuBreak size='xxl' />
    </BlogFooterRoot>
  )
})

const BlogFooterRoot = styled.div`
  padding: 0 16px;
`
