import React, { Fragment, FunctionComponent } from 'react'
import { RyuBreak, withRyuErrorBoundary } from '@ramp/ryu'
import styled from 'styled-components'

import { BlogPostCTA } from '~/src/components/BlogPostCTA'
import { BlogPostMetadata } from '~/src/components/BlogPostMetadata'
import { GrungeHighlight } from '~/src/components/GrungeHighlight'
import { TitledContent } from '~/src/components/TitledContent'
import { BlogPost } from '~/src/types'
import { BlogPostAttribution } from './components/BlogPostAttribution'
import { BLOG_HOME_BREAKPOINTS, BLOG_CONTENT_MAX_WIDTH, RYU_SPACING } from '~/src/styles/constants'
import { BlogShowOnMobile } from '~/src/components/BlogShowOnMobile'

type Props = {
  blogPost: BlogPost
}

const BlogPostRoute: FunctionComponent<Props> = withRyuErrorBoundary(({ blogPost }) => {
  const { title, MdxContent } = blogPost

  return (
    <Fragment>
      <BlogPostMetadata blogPost={blogPost} />
      <TitledContent title={title}>
        <PostPageContainer>
          <BlogPostHeading>
            <Highlight>{title}</Highlight>
          </BlogPostHeading>

          <RyuBreak size='l' />

          <BlogPostContainer>
            <BlogSidebarContainer>
              <BlogPostAttribution blogPost={blogPost} disableLink={true} />

              <BlogShowOnMobile>
                <RyuBreak size='l' />
              </BlogShowOnMobile>
            </BlogSidebarContainer>

            <BlogContentContainer>
              <MdxContent />

              <RyuBreak size='xl' />

              <BlogPostCTA />
            </BlogContentContainer>
          </BlogPostContainer>
        </PostPageContainer>
      </TitledContent>
    </Fragment>
  )
})

const BlogPostContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  @media (min-width: ${BLOG_HOME_BREAKPOINTS.tablet}) {
    flex-direction: row;
    gap: ${RYU_SPACING[14]};
  }
`

const BlogSidebarContainer = styled.div`
  @media (min-width: ${BLOG_HOME_BREAKPOINTS.tablet}) {
    max-width: 288px;
  }
`

const BlogContentContainer = styled.div`
  max-width: 678px;
`

const Highlight = styled(GrungeHighlight)`
  display: block;
  width: 100%;
`

const BlogPostHeading = styled.h1`
  font-family: Menlo;
  font-style: normal;
  font-weight: 400;
  line-height: 115%;
  letter-spacing: -0.96px;
  text-transform: uppercase;
  width: 100%;
  display: block;
  word-break: break-word;
  font-size: ${RYU_SPACING[8]};

  @media (min-width: ${BLOG_HOME_BREAKPOINTS.tablet}) {
    font-size: ${RYU_SPACING[9]};
  }

  @media (min-width: ${BLOG_HOME_BREAKPOINTS.desktop}) {
    font-size: ${RYU_SPACING[12]};
  }
`

const PostPageContainer = styled.div`
  max-width: ${BLOG_CONTENT_MAX_WIDTH}px;
  margin: 0 auto;

  @media (min-width: ${BLOG_HOME_BREAKPOINTS.desktop}) {
    padding: 0 ${RYU_SPACING[6]}px;
  }
`

export function withBlogPost(blogPost: BlogPost) {
  const BlogPost = () => <BlogPostRoute blogPost={blogPost} />
  return BlogPost
}
