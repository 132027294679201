
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import { RyuImage, RyuTextLabel } from '@ramp/ryu'
import pablo_elixir_nyc from './pablo_elixir_nyc.jpg'
import hello_joe from './hello_joe.jpg'
import PPelixir from './PPelixir.png'
import PPerlang from './PPerlang.png'
export const meta = {
  date: '2021-05-24T17:00:00.000Z',
  title: 'Elixir at Ramp 🧪',
  description: 'Pablo talks about how and why we use Elixir at Ramp.',
  authors: [{
    name: 'Pablo Meier',
    twitter: '@SrPablo',
    website: 'https://morepablo.com',
    photo: pablo_elixir_nyc
  }]
};

const layoutProps = {
  meta
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`Many fintech startups have found `}<a parentName="p" {...{
        "href": "https://elixir-lang.org/"
      }}>{`Elixir`}</a>{` to be an excellent fit for their
needs — Divvy, Brex, `}<a parentName="p" {...{
        "href": "https://elixirforum.com/t/solarisbank-ag-looking-for-immediate-freelance-permanent-elixir-engineers-ideally-berlin-based/35077"
      }}>{`Solarisbank AG`}</a>{`, `}<a parentName="p" {...{
        "href": "https://angel.co/company/tellerapi/jobs/644392-elixir-engineer"
      }}>{`Teller`}</a>{`, with the tradition
extending back to `}<a parentName="p" {...{
        "href": "https://github.com/klarna?language=erlang"
      }}>{`Klarna using Erlang`}</a>{`.`}</p>
    <p>{`Hi, I'm `}<a parentName="p" {...{
        "href": "https://morepablo.com"
      }}>{`Pablo`}</a>{`! 👋 I've been an unapologetic fan of Erlang for the better
part of the last decade, and one of `}<a parentName="p" {...{
        "href": "https://ramp.com"
      }}>{`Ramp`}</a>{`'s first hires. When I was
asked to design a few of our systems, I was delighted to choose Elixir as well.
I'll go over a few of the reasons it's a great fit for a few of the systems
we've rolled out.`}</p>
    <RyuImage src={pablo_elixir_nyc} alt='Pablo at Elixir NYC' mdxType="RyuImage" />
    <RyuTextLabel mdxType="RyuTextLabel">Me at ElixirNYC; one of the last things I did before lockdown.</RyuTextLabel>
    <h2>{`The magic of BEAM and OTP…`}</h2>
    <p>{`Most "Why Elixir" posts go on to describe the features of `}<a parentName="p" {...{
        "href": "https://blog.lelonek.me/elixir-on-erlang-vm-demystified-320557d09e1f"
      }}>{`BEAM and the OTP`}</a>{`
(Erlang's virtual machine and a library of abstractions for reliable, concurrent
systems, respectively). And for good reason! Whether you're in Erlang or Elixir,
the built-in resources for complex, distributed, high-reliability systems are
unique compared to most programming environments and extremely valuable. `}<a parentName="p" {...{
        "href": "https://www.deconstructconf.com/2018/pablo-meier-a-fresh-look-on-failure"
      }}>{`I
spoke at length about them at Deconstruct 2018`}</a>{`. I'll also bullet
out some points here, with links to deeper dives if you're curious:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Fantastic support for concurrency and parallelism that's designed to avoid
system failure. See `}<a parentName="p" {...{
            "href": "https://www.youtube.com/watch?v=JvBT4XBdoUE"
          }}>{`The soul of Erlang and Elixir`}</a>{` by Saša Jurić. Ask
yourself as you watch "How would I implement a system with these properties in
Ruby, Node, Python, or Java?"`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Correctness guarantees for partial failures and transient errors (see `}<a parentName="p" {...{
            "href": "https://ferd.ca/it-s-about-the-guarantees.html"
          }}>{`It's
About Guarantees`}</a>{` by Fred Hebert). Transient errors are extremely common
and most environments don't have a great mechanism for handling them.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Efficient string construction, especially useful in template rendering
(`}<a parentName="p" {...{
            "href": "https://www.evanmiller.org/elixir-ram-and-the-template-of-doom.html"
          }}>{`Elixir RAM and the Template of Doom`}</a>{` by Evan Miller).`}</p>
      </li>
    </ul>
    <p>{`Succinctly, BEAM is a battle-tested VM for resilient, concurrent applications,
and OTP builds abstractions over it that are hard to find in other environments.
`}<strong parentName="p">{`These have done more to change how I think about programming systems than
anything else I've encountered since graduating, and I miss these features in
every other environment I've worked on since.`}</strong></p>
    <RyuImage src={PPerlang} alt='A necromancer labeled "Erlang," standing over an army of dead, saying "Destroy
one of my processes and I will only grow stronger"' mdxType="RyuImage" />
    <RyuTextLabel mdxType="RyuTextLabel">
  (from <a href='https://leftoversalad.com/c/015_programmingpeople/'>this amazing comic</a>)
    </RyuTextLabel>
    <h2>{`…with a great, modern developer experience`}</h2>
    <p>{`So move it all to Erlang? Not quite. Even with the magic of OTP/BEAM, Erlang can
be challenging to adopt. Amazing work has been done to improve this, from
software like `}<a parentName="p" {...{
        "href": "https://rebar3.readme.io/docs/getting-started"
      }}>{`rebar3`}</a>{` to documentation like `}<a parentName="p" {...{
        "href": "https://adoptingerlang.org/"
      }}>{`Adopting Erlang`}</a>{`. While I
highly recommend everyone give Erlang a try, many of the conveniences of Elixir
made it easier to choose for a high-growth company setting.`}</p>
    <p>{`First, the syntax is more familiar for programmers from other environments
(here's an example of `}<a parentName="p" {...{
        "href": "https://www.rosettacode.org/wiki/Create_an_HTML_table#Erlang"
      }}>{`Erlang`}</a>{` compared to `}<a parentName="p" {...{
        "href": "https://www.rosettacode.org/wiki/Create_an_HTML_table#Elixir"
      }}>{`Elixir`}</a>{`). Syntax isn't
personally interesting for me, and I happen to find Erlang beautiful, but I'd be
in denial if I pretended I didn't observe a meaningful negative reaction from
others when I tried introducing them to Erlang.`}</p>
    <p>{`Second, tools follow a stricter set of conventions, often with very
human-friendly ergonomics. Some examples:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li"><inlineCode parentName="p">{`mix test`}</inlineCode>{` on a generated `}<a parentName="p" {...{
            "href": "https://www.phoenixframework.org/"
          }}>{`Phoenix`}</a>{` project will structure tests for
concurrency by default with a `}<a parentName="p" {...{
            "href": "https://hexdocs.pm/ecto_sql/Ecto.Adapters.SQL.Sandbox.html"
          }}>{`database sandboxing environment`}</a>{` such
that it's harder to design a test suite whose runtime will grow
superlinearly over time. Did you only want to run tests that failed?
There's `}<inlineCode parentName="p">{`mix test --failed`}</inlineCode>{`! There's also `}<a parentName="p" {...{
            "href": "https://twitter.com/josevalim/status/1363791170745348097"
          }}>{`a way to run only on `}<inlineCode parentName="a">{`stdin`}</inlineCode>{` if
you want to work on updated tests.`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`There's also `}<inlineCode parentName="p">{`iex`}</inlineCode>{`, the Elixir REPL. With the BEAM's hot code reloading,
when you open a REPL, you can reload the module you're working on with `}<inlineCode parentName="p">{`r MyModule`}</inlineCode>{` after a change, so you can use a REPL without having to close and
open it on a regular basis. Both of these allow rapid test execution and
rapid prototyping/turnaround.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Many of the tools have friendlier error messages. I ran into an issue with
Ecto one time `}<a parentName="p" {...{
            "href": "https://stackoverflow.com/a/56430391/196469"
          }}>{`and the creator of Elixir answered my question on
StackOverflow, while also pushing a commit to clarify the error message in
the library.`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Documentation culture: Elixir libraries tend to have amazing documentation,
evidenced by tools like `}<a parentName="p" {...{
            "href": "https://elixir-lang.org/getting-started/mix-otp/docs-tests-and-with.html#doctests"
          }}>{`doctests`}</a>{` that will run the snippets in your
docs to ensure your examples match with reality. Additionally,
`}<a parentName="p" {...{
            "href": "https://pragprog.com/categories/elixir-phoenix-and-otp/"
          }}>{`PragProg`}</a>{` offers a ton of great books that I've personally enjoyed.`}</p>
      </li>
    </ul>
    <p>{`Finally (and this deserves a post of its own), consider the ergonomics afforded
by having tools like `}<a parentName="p" {...{
        "href": "https://elixir-lang.org/getting-started/meta/macros.html"
      }}>{`a macro system`}</a>{` and `}<a parentName="p" {...{
        "href": "https://elixir-lang.org/getting-started/sigils.html"
      }}>{`sigils.`}</a>{` Macros are highly
romanticized, and there's much justified danger of using them too freely when a
function will do; with that said, the flexibility can be used to great effect:
much of Pheonix, Ecto, and ExUnit's more declarative constructs come from the
use of these.`}</p>
    <RyuImage src={PPelixir} alt='A sweet, Sailor Moon-ey cute anime girl magician saying "I draw Eldritch Power from all my process-chans!"' mdxType="RyuImage" />
    <RyuTextLabel mdxType="RyuTextLabel">
  (source same as above. Also note that all the points 👆 aren't just beneficial in contrast to Erlang,
  but stand strong next to any other programming ecosystem)
    </RyuTextLabel>
    <h2>{`Dynamic types, while avoiding the pitfalls`}</h2>
    <p>{`I'll make a special mention for two specific features I miss most in
environments like Python, Node, or Ruby: `}<em parentName="p">{`immutability`}</em>{` and `}<em parentName="p">{`module structure
with aliased calls.`}</em>{` My hot take about most dynamic languages is that they are a
poor fit for startups who have intentions of being long-term businesses: you've
created an environment that's optimized for your founding engineers to build
something quickly in the first 7 months, but the price is a set of recurring
obstacles which your engineers will pay down over the next 7 years. That
microframework where all 4 of your engineers kept the first 10k
LOC in their heads starts to be much harder to work with when you hit with 40
engineers and 400k LOC (and beyond! oh, and you have customers now, too).`}</p>
    <p>{`Obviously companies have gone on to great success on all kinds of technologies,
and getting to product-market fit matters a lot more than optimizing for life
after it; but I don't think it should be controversial to say `}<em parentName="p">{`when
trying to understand or modify large systems, it helps to have invariants`}</em>{`, and
most dynamic languages don't give you many. Elixir's immutability shines here.
Consider the following lines of Elixir:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-elixir"
      }}>{`defmodule MyApp.Widgets do

  alias MyApp.WidgetConnection

  def augment_widget(conn, w = %Widget{}) do
    abbreviation = WidgetConnection.find_abbreviation(conn, w)

    formats =
      abbreviation.signals
      |> Enum.map(&dependencies_for/1)
      |> Enum.filter(& applicable_to(&1, w))

      %{w | formats: formats}
  end

  # defp dependencies_for(signal) do
  # defp applicable_to(signal_deps, widget) do
end
`}</code></pre>
    <p>{`compared to something like this in Python:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-python"
      }}>{`from myapp.widgetconnection import find_abbreviation

def augment_widget(conn: Connection, w: Widget) -> None:
  abbreviation = find_abbreviation(conn, w)
  mapped = [_dependencies_for(s) for s in abbreviation.signals]
  formats = [s for s in mapped if _applicable_to(s, w)]
  w.formats = formats

# def _dependencies_for(signal):
# def _applicable_to(signal_deps, widet):
`}</code></pre>
    <p>{`Pay attention to what you know `}<em parentName="p">{`isn't`}</em>{` happening in the Elixir example:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`You know `}<inlineCode parentName="p">{`conn`}</inlineCode>{` and `}<inlineCode parentName="p">{`w`}</inlineCode>{` `}<em parentName="p">{`will not change`}</em>{` in the call to `}<inlineCode parentName="p">{`find_abbreviation`}</inlineCode>{`.
In most environments, you don't know if your structures will mutate outside of
where you can see them, so you're not sure to what degree you can count on
them further in the execution. In an immutable language, you can trust the
data is the same for the whole function body while reading it.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`We didn't `}<inlineCode parentName="p">{`import`}</inlineCode>{` WidgetConnection, we `}<inlineCode parentName="p">{`alias`}</inlineCode>{`-ed it, meaning we merely added
some syntactic sugar to a fully qualified function call. This, combined with
the restriction that Elixir modules can't contain code that executes at the
toplevel, means we don't have to worry about circular imports: In
Elixir, both modules can `}<inlineCode parentName="p">{`alias`}</inlineCode>{` each other, but in Python, you can't have
both `}<inlineCode parentName="p">{`import`}</inlineCode>{` each other's definitions.`}</p>
      </li>
    </ul>
    <p>{`So as the Elixir app grows and grows, an entire class of uncertainty over "what
is this code in front of me doing" goes away, because there's a ton of dangerous
behavior you know it's `}<em parentName="p">{`not`}</em>{` doing. You're not home free! Function calls to
other modules could still call the network, or write to the DB, or delete files.
But you're certain it's not changing the variables you are looking at, and you
don't need to keep a universe of state changes in your head to understand what
the code is doing.`}</p>
    <p>{`Additionally, "make that module's code visible to this code" has a rash of
problems we avoid as well. This can get complicated! `}<a parentName="p" {...{
        "href": "https://blog.khanacademy.org/the-great-python-refactor-of-2017-and-also-2018/"
      }}>{`Khan Academy once spent 2
months moving files around`}</a>{`, and `}<a parentName="p" {...{
        "href": "https://instagram-engineering.com/python-at-scale-strict-modules-c0bb9245c834"
      }}>{`Instagram Engineering explicitly talks
about wanting some of these contraints`}</a>{` in Python's module system. `}<a parentName="p" {...{
        "href": "https://dropbox.tech/application/our-journey-to-type-checking-4-million-lines-of-python"
      }}>{`Here's
Dropbox Engineering describing "the tangle,"`}</a>{` on how Python's module loading
complicated their type checker, since module dependencies can become impossible
to tease out at scale. Using `}<inlineCode parentName="p">{`alias`}</inlineCode>{` means not having to worry about a
dependency tree in the definitions stage.`}</p>
    <p>{`This is not to pick on Python in particular; just again to highlight a strength
in a core design choice of Erlang and Elixir that made it appealing to me as a
developer who's been burnt on large codebases in other languages.`}</p>
    <RyuImage src={hello_joe} alt='reverentgeek graphic of Joe Armstrong, with a quote of his: "Make it work, then make it beautiful, then if you really, really have to, make it fast. 90% of the time, if you make it beautiful, it will already be fast. So just make it beautiful.' mdxType="RyuImage" />
    <RyuTextLabel mdxType="RyuTextLabel">
  Joe Armstrong, drawn after his passing.{' '}
  <a href='https://twitter.com/reverentgeek/status/1120304982329372672'>(source)</a>. Also, one thing the
  other languages do better than Erlang/Elixir? Bolted-on type checkers.{' '}
  <a href='https://mypy.readthedocs.io/en/stable/'>mypy</a> or{' '}
  <a href='https://www.typescriptlang.org/'>TypeScript</a> or <a href='https://sorbet.org/'>Sorbet</a>{' '}
  have a lot of great qualities missing in the humble{' '}
  <a href='https://learnyousomeerlang.com/dialyzer'>Dialyzer.</a>
    </RyuTextLabel>
    <h2>{`Augmenting, but choice of tech is only part of it`}</h2>
    <p>{`Ramp uses Elixir for a few specific, critical services: our real-time
Authorization engine (which requires extremely high uptime) and our Risk
analysis platform (requiring strong concurrency and data pipeline
ingestion). That said, it isn't what powers most of our business: our largest
service is in Python, and our frontend is in TypeScript with React. When
looking at how one can best work towards engineering success, rather than
specific tech, I think it's more important to ask:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Is your team `}<a parentName="p" {...{
            "href": "https://increment.com/teams/the-epistemology-of-software-quality/"
          }}>{`getting enough sleep, and avoiding high-stress conditions?`}</a>{`
A little opt-in hustle is fine, but `}<strong parentName="p">{`do not crunch`}</strong>{`.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Do you trust your teammates to support `}<em>{`and`}</em>{` challenge you? Nasty
conflict may be a sign of bad team dynamics, but `}<a parentName="p" {...{
            "href": "https://blog.capterra.com/strong-teams-why-conflict-is-essential-for-true-commitment/"
          }}>{`lack of healthy conflict is
also an anti-pattern`}</a>{`, signaling low investment or trust.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Can you assign your team tasks that you trust and empower them `}<a parentName="p" {...{
            "href": "https://hbr.org/2015/12/how-to-make-employees-feel-like-they-own-their-work"
          }}>{`to own?`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Do your team members `}<a parentName="p" {...{
            "href": "https://rework.withgoogle.com/guides/understanding-team-effectiveness/steps/foster-psychological-safety/"
          }}>{`feel psychologically safe to participate, to innovate,
and to invest?`}</a></p>
      </li>
    </ul>
    <p>{`Clearly, `}<em parentName="p">{`I love what Elixir allows me to do.`}</em>{` But I'll gleefully invest money
and time with a company honoring the above properties over any technical choice
they make. When I joined Ramp, there was a clear preference for Python: the
founders previously built and scaled `}<a parentName="p" {...{
        "href": "https://paribus.co"
      }}>{`Paribus`}</a>{` on it, and brought a lot of
expertise with them. And not for nothing: Python can be an absolute joy to use ❤️
So our largest backend codebase is written in Python, and it's where most of our
developers spend their time.`}</p>
    <p>{`But in service of the points above: our CTO asked me to design our
real-time, high-uptime Authorization service, he created an environment where I
felt safe enough to propose a different tech stack, and gave me the authority
and ownership to move forward on it. It's been exceptionally stable, flexible
enough to allow for rapid feature development, and it's some of the code I'm
most proud of in my life. Good tech doesn't merely coexist with good team
culture and management, it depends on it, so as much fun as I have fun with tech
talk like this post, it definitely has a ceiling on its utility when building a
business.`}</p>
    <p>{`I hope I've piqued your interest in trying Elixir if you weren't considering it
before 😄 Also (and you knew it was coming), `}<a parentName="p" {...{
        "href": "https://ramp.com/careers"
      }}><strong parentName="a">{`please reach out if you're
looking for your next gig!`}</strong></a>{` The magic of the last two years with this
company has been its people, and we're always looking for the next ones to keep
building with ❤️`}</p>
    <p>{`And introduce us whoever manages spend at your company! Our product
rules: we've helped thousands of customers like Ro, Better.com and ClickUp
identify over $10M in wasteful spend, eliminate manual + broken expense reports,
and close their books 86% faster. Users love the impact our software
drives - we're the #1 rated spend management vendor on G2. Put us in touch with
your finance and accounting teams so that we can help your company spend more
intelligently!`}</p>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;