import React, { Fragment, FunctionComponent, useMemo } from 'react'
import { RyuPad, withRyuErrorBoundary } from '@ramp/ryu'
import { Helmet } from 'react-helmet-async'
import { Route, Switch } from 'react-router-dom'
import styled from 'styled-components'

import { BlogFooter } from '~/src/components/BlogFooter'
import { BlogHeader } from '~/src/components/BlogHeader'
import { GlobalStyles } from '~/src/components/GlobalStyles'
import { blogPosts } from '~/src/utils/blog'

import { BlogHomeRoute } from './BlogHomeRoute'
import { withBlogPost } from './BlogPostRoute'
import { NotFoundRoute } from './NotFoundRoute'
import { GraphPaper } from '../components/GraphPaper'
import { BLOG_DESCRIPTION, BLOG_TITLE, DEFAULT_OG_IMAGE, ORIGIN, RAMP_TWITTER } from '../utils/metadata'

const RoutesRoot = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`

const RoutesContent = styled.div`
  flex-grow: 1;
`

export const Routes: FunctionComponent = () => {
  const blogPostRoutes = useMemo(
    () =>
      blogPosts.map((blogPost) => (
        <Route
          key={blogPost.path}
          exact={true}
          path={blogPost.path}
          component={withBlogPost(blogPost)}
        />
      )),
    []
  )

  return (
    <Fragment>
      <Helmet>
        {/* HTML Meta Tags  */}
        <title>{BLOG_TITLE}</title>
        <meta
          name='description'
          property='og:description'
          itemProp='description'
          content={BLOG_DESCRIPTION}
        />
        <link rel='canonical' href={ORIGIN} />

        {/* Open Graph / Facebook Meta Tags */}
        <meta property='og:url' itemProp='url' content={ORIGIN} />
        <meta property='og:image' itemProp='images' content={DEFAULT_OG_IMAGE} />
        <meta property='og:title' content={BLOG_TITLE} />
        <meta property='og:type' content='website' />
        <meta property='og:description' content={BLOG_DESCRIPTION} />

        {/* Twitter Meta Tags */}
        <meta name='twitter:card' content='summary_large_image' />
        <meta name='twitter:title' itemProp='name' property='og:title' content={BLOG_TITLE} />
        <meta name='twitter:description' content={BLOG_DESCRIPTION} />
        <meta property='twitter:domain' content='engineering.ramp.com' />
        <meta property='twitter:url' content={ORIGIN} />
        <meta name='twitter:site' content={RAMP_TWITTER} />
        <meta name='twitter:creator' content={RAMP_TWITTER} />
        <meta name='twitter:image' content={DEFAULT_OG_IMAGE} />
      </Helmet>

      <GraphPaper>
        <RoutesRoot>
          <BlogHeader />

          <RoutesContent>
            <RyuPad size='l' sizeVertical='l'>
              <Switch>
                <Route exact={true} path='/' component={withRyuErrorBoundary(BlogHomeRoute)} />

                {blogPostRoutes}

                <Route component={withRyuErrorBoundary(NotFoundRoute)} />
              </Switch>
            </RyuPad>
          </RoutesContent>

          <BlogFooter />
        </RoutesRoot>
      </GraphPaper>

      <GlobalStyles />
    </Fragment>
  )
}
