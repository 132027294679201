import React, { FunctionComponent } from 'react'
import { RyuBreak, RyuFlex, RyuIcon, RyuText } from '@ramp/ryu'
import styled from 'styled-components'
import { ReceiptHeader } from '../ReceiptCard'
import { NeobrutalismButton } from '../NeobrutalismButton'
import { CAREERS_URL } from '~/src/urls'
import { PRIMARY_COLOR, RYU_SPACING } from '~/src/styles/constants'

export const BlogPostCTA: FunctionComponent = () => {
  return (
    <BlogCTARoot>
      <ReceiptHeader.h5>Like what you see?</ReceiptHeader.h5>

      <RyuBreak size='s' />

      <RyuText color='primary'>
        Come build the future of finance automation that increases the lifespan of businesses.
      </RyuText>

      <RyuBreak size='l' />

      <NeobrutalismButton as='a' href={CAREERS_URL} target='_blank' rel='noopener noreferrer'>
        <RyuFlex alignItems='center' marginSize='xs'>
          Apply now <RyuIcon type='arrow-up-right' />
        </RyuFlex>
      </NeobrutalismButton>
    </BlogCTARoot>
  )
}

const BlogCTARoot = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: ${RYU_SPACING[6]};
  border: 1px dashed ${PRIMARY_COLOR};
  text-align: center;
`
