import React from 'react'

import { RyuBreak, RyuFlex, RyuIcon } from '@ramp/ryu'
import { ViewRevealText } from '../../../components/RevealText'
import styled from 'styled-components'
import { GrungeHighlight } from '~/src/components/GrungeHighlight'
import { CAREERS_URL } from '~/src/urls'
import { NeobrutalismButton } from '~/src/components/NeobrutalismButton'
import { BLOG_HOME_BREAKPOINTS } from '../../../styles/constants'
import { ReceiptBody, ReceiptHeader } from '~/src/components/ReceiptCard'

const FOUNDING_DATE = new Date('2019-03-18')

const DAYS_SINCE_FOUNDING = Math.floor((Date.now() - FOUNDING_DATE.getTime()) / (1000 * 60 * 60 * 24))

export function DayOneFooter() {
  return (
    <RyuFlex direction='column' alignItems='center' marginSize='m'>
      <ReceiptHeader.h5>It's day</ReceiptHeader.h5>
      <Countdown>
        <ViewRevealText text={DAYS_SINCE_FOUNDING.toString()} />
      </Countdown>
      <ReceiptHeader.h5>at Ramp</ReceiptHeader.h5>
      <RyuBreak size='xs' />
      <ReceiptBody.p size='l' textAlign='center'>
        Tomorrow could be <GrungeHighlight>day 0</GrungeHighlight> for you
      </ReceiptBody.p>
      <NeobrutalismButton as='a' href={CAREERS_URL} target='_blank' rel='noreferrer'>
        <RyuFlex alignItems='center' marginSize='xs'>
          Apply now <RyuIcon type='arrow-up-right' />
        </RyuFlex>
      </NeobrutalismButton>
    </RyuFlex>
  )
}

const Countdown = styled.h5`
  text-align: center;
  font-family: Menlo;
  font-size: 80px;
  font-style: normal;
  font-weight: 400;
  line-height: 95.573px;
  letter-spacing: -2.458px;

  @media (min-width: ${BLOG_HOME_BREAKPOINTS.tablet}) {
    font-size: 122.88px;
  }
`
