import { RyuBreak } from '@ramp/ryu'
import React, { Fragment, FunctionComponent, ReactNode, useEffect } from 'react'
import { Helmet } from 'react-helmet-async'
import { useLocation } from 'react-router'
import { BLOG_TITLE } from '~/src/utils/metadata'

type Props = {
  title?: string
  children: ReactNode
}

function ScrollToTop() {
  const { pathname } = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  return null
}

export const TitledContent: FunctionComponent<Props> = ({ title, children }) => {
  return (
    <Fragment>
      <Helmet>
        <title>{title ? `${title} — ${BLOG_TITLE}` : BLOG_TITLE}</title>
      </Helmet>

      <RyuBreak />

      <ScrollToTop />

      {children}
    </Fragment>
  )
}
