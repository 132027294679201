import React, { FunctionComponent } from 'react'
import { withRyuErrorBoundary } from '@ramp/ryu'
import { Helmet } from 'react-helmet-async'

import { BlogPost } from '~/src/types'
import { DEFAULT_OG_IMAGE, ORIGIN, RAMP_TWITTER } from '~/src/utils/metadata'

type Props = {
  blogPost: BlogPost
}

export const BlogPostMetadata: FunctionComponent<Props> = withRyuErrorBoundary(
  ({ blogPost }) => {
    const { date, path, title, description, thumbnail, authors } = blogPost
    const authorNames = authors.map((a) => a.name).join(', ')
    const authorWebsite = authors.find((a) => a.website)?.website
    const authorTwitter = authors.find((a) => a.twitter)?.twitter

    const ogImage = thumbnail ? ORIGIN + thumbnail : DEFAULT_OG_IMAGE

    return (
      <Helmet>
        {/* HTML Meta Tags */}
        <meta
          name='description'
          property='og:description'
          itemProp='description'
          content={description}
        />
        <link rel='canonical' href={ORIGIN + path} />
        <meta name='author' itemProp='creator' content={`${authorNames} <${authorWebsite}>`} />
        <meta name='dateCreated' content={date.toISOString()} />
        <meta name='dateModified' content={date.toISOString()} />
        <meta name='datePublished' content={date.toISOString()} />

        {/* Open Graph / Facebook Meta Tags */}
        <meta property='og:url' itemProp='url' content={ORIGIN + path} />
        <meta property='og:image' itemProp='images' content={ogImage} />
        <meta property='og:type' content='article' />
        <meta property='og:title' content={title} />
        <meta property='og:description' content={description} />

        {/* Twitter Meta Tags */}
        <meta name='twitter:card' content='summary_large_image' />
        <meta name='twitter:title' itemProp='name' property='og:title' content={title} />
        <meta name='twitter:description' content={description} />
        <meta name='twitter:site' content={RAMP_TWITTER} />
        <meta name='twitter:creator' content={authorTwitter} />
        <meta name='twitter:image' content={ogImage} />
      </Helmet>
    )
  },
  { inline: true }
)
