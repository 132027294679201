import styled from 'styled-components'
import { BLOG_HOME_BREAKPOINTS } from '~/src/styles/constants'

export const RegularPageContainer = styled.div`
  max-width: 1040px;
  margin: 0 auto;

  @media (min-width: ${BLOG_HOME_BREAKPOINTS.tablet}) {
    padding: 0 16px;
  }
`
