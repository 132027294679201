
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import { RyuImage, RyuFlex } from '@ramp/ryu'
import pablo_elixir_nyc from '../elixir-at-ramp/pablo_elixir_nyc.jpg'
import churn_feathers from './churn-michael-feathers.jpg'
import churn_angular from './churn-angular-js.jpg'
import churn_discourse from './churn-discourse.jpg'
import churn_gitlab from  './churn-gitlabhq.jpg'
import do_not_remove from './do-not-remove.jpg'
export const meta = {
  date: '2023-03-04T17:00:00.000Z',
  title: 'Responsibly mid-size: What Matters, Suffers',
  description: 'Questioning what is gained from "clean code" in actual, production environments.',
  authors: [{
    name: 'Pablo Meier',
    website: 'https://morepablo.com',
    twitter: '@SrPablo',
    position: 'Engineering',
    bio: 'Pablo is a Founding Engineer at Ramp.',
    photo: pablo_elixir_nyc
  }]
};
const makeShortcode = name => function MDXDefaultShortcode(props) {
      console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
      return <div {...props}/>
    };
const RyuTextLabel = makeShortcode("RyuTextLabel");
const layoutProps = {
  meta
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`In interviews, I'm sometimes asked what I think the biggest existential threat
to Ramp is, and I often say "doing mid-size responsibly." We've raised a lot of
money, hired a lot of people since the early days of Eric and Karim's apartments,
and are now, solidly, mid-sized. What does it mean to hit mid-size responsibly?
And how can we leverage experience from a decade in high-growth tech companies
as we sustain flight here?`}</p>
    <p>{`When describing "the game" of being mid-size day-to-day, I often reference the
Serenity Prayer:`}</p>
    <blockquote>
      <p parentName="blockquote">{`God, grant me the serenity `}<br />{`
to accept the things I cannot change, `}<br />{`
the courage to change the things I can, `}<br />{`
and the wisdom to know the difference. `}<br /></p>
    </blockquote>
    <p>{`A mid-size company cannot do the same things they did when they were small. They
will need to adopt what feels like "big company nonsense" in order to grow. But
this needs to be piecemeal, you don't hit PMF and suddenly operate like a FAANG.
Every day, people at the company will have to have the courage to adopt a new
Process or Attitude when they need to, accept it when they shouldn't, and have
the wisdom to know the difference.`}</p>
    <p>{`What's an example of a "responsibly mid-size" attitude? I'm going to talk about
(and challenge) the idea of "clean code" in any codebase that has real
responsibilities. This is adapted from a presentation I gave internally; it's
not in response to the Bad Thing Being Described (in my 7 companies, I've never
felt more confident in my peers), it was more given as a preventative, and a bit
of "what I wish I'd heard when I was in my late 20's working at other
high-growth startups." I hope it's useful to someone.`}</p>
    <h2>{`"Bad code" is inevitable`}</h2>
    <p>{`These observations are summarized from a Sandi Metz talk, `}<a parentName="p" {...{
        "href": "https://www.deconstructconf.com/2018/sandi-metz-polly-want-a-message"
      }}>{`Polly Want A
Message`}</a>{`, which in turn references other writing. I highly encourage anyone
to watch the full talk, read `}<a parentName="p" {...{
        "href": "https://sandimetz.com/blog"
      }}>{`her blog`}</a>{`, or check out `}<a parentName="p" {...{
        "href": "https://sandimetz.com/99bottles"
      }}>{`the book on OOP she
co-wrote`}</a>{`. First I'll mention that `}<em parentName="p">{`"bad code" is effectively inevitable.`}</em></p>
    <p>{`She links to `}<a parentName="p" {...{
        "href": "https://www.stickyminds.com/article/getting-empirical-about-refactoring"
      }}>{`Michael Feather's `}<em parentName="a">{`Getting Empirical About Refactoring`}</em></a>{`, which
introduces the idea of "churn" as how often a file changes. Considering your
most-touched files are interesting, but if you plot them with semantic
complexity within the file, you get an interesting picture (and she adds the
green trend line):`}</p>
    <RyuImage src={churn_feathers} alt='A chart showing Churn vs. Complexity, with a green line sloped curved, concave, downward. Most are clustered at the bottom-left, a small cluster at the top-left, a small cluster at the bottom right, and one file in the top-right' mdxType="RyuImage" />
    <p>{`Most files in a codebase live in at most one extreme: left and low (few changes,
low complexity), left and high (complex but rarely touched, think that one
gnarly feature that doesn't require much development after
launch), or right and low (touched a lot, but not complex at all: think config
files).`}</p>
    <p>{`Except that one little buddy on the top right: Changes a lot `}<em parentName="p">{`and`}</em>{` complex?! I
hope it's not important.`}</p>
    <p>{`A consultant of many, many software shops, Sandi says in the talk:`}</p>
    <blockquote>
      <p parentName="blockquote">{`If I looked at the churn versus complexity chart for your own app, I can tell
you what's up in that right-hand corner. It's a class that's big, much bigger
than the average size of the classes in your system. It's complicated. It has
a bunch of conditionals in it. And it's about something that's super important
to your domain. `}{`[...]`}{` If you're doing contracts, it's `}<inlineCode parentName="p">{`Contract`}</inlineCode>{`.`}</p>
    </blockquote>
    <p>{`My first gig was Flash Player; the most churned file was also the biggest,
`}<inlineCode parentName="p">{`splayer.cpp`}</inlineCode>{` (the core player logic), which was around 22k lines when I was
there. Most of it was one big `}<inlineCode parentName="p">{`switch`}</inlineCode>{` statement.`}</p>
    <p>{`She links a few graphics from Code Climate, a tool that measure code health on
open source projects, and you can see almost every project has one of these:`}</p>
    <RyuImage src={churn_angular} alt='A Code Climate chart showing Churn vs. Complexity for the AngularJS project; it has the same cluster with one problematic point on the top right' mdxType="RyuImage" />
    <RyuImage src={churn_discourse} alt='A Code Climate chart showing Churn vs. Complexity for the Discourse project; it has the same cluster with one problematic point on the top right' mdxType="RyuImage" />
    <RyuImage src={churn_gitlab} alt='A Code Climate chart showing Churn vs. Complexity for the GitLab project; it has the same cluster with one problematic point on the top right' mdxType="RyuImage" />
    <p>{`You can find your 30 most churned files by running`}</p>
    <pre><code parentName="pre" {...{}}>{`git log --name-only --pretty=format: | sort | uniq -c | sort -nr | head -n 30
`}</code></pre>
    <p>{`Really, watch the talk, or `}<a parentName="p" {...{
        "href": "https://sandimetz.com/blog/2017/9/13/breaking-up-the-behemoth"
      }}>{`read her blog post presenting this material`}</a>{` (she
includes a few more points on Object-Orientation vs. simple procedures). I
summarize it here to tell you `}<strong parentName="p">{`"nasty" code is emergent to the point of being
inevitable, and right where you don't want it. If it's in all these successful,
`}<em parentName="strong">{`useful`}</em>{` codebases, maybe seeing it yours doesn't mean something bad is
happening.`}</strong></p>
    <h2>{`"Bad code" might not be`}</h2>
    <p>{`Another example, namechecking a very famous axiom: should you rewrite your
app from scratch? Almost every CTO would give an emphatic `}<em parentName="p">{`"NO!"`}</em>{`, and they'd
be right, and they'd probably cite the original `}<a parentName="p" {...{
        "href": "https://www.joelonsoftware.com/2000/04/06/things-you-should-never-do-part-i/"
      }}><em parentName="a">{`Joel on Software`}</em>{` article
about it`}</a>{`. But when you have an `}<em parentName="p">{`ick`}</em>{` reaction to code, think of what Joel
said on that too:`}</p>
    <blockquote>
      <p parentName="blockquote">{`[...]`}{` you can ask almost any programmer today about the code they are working on.
"It’s a big hairy mess," they will tell you. "I’d like nothing better than
to throw it out and start over."`}</p>
      <p parentName="blockquote">{`Why is it a mess?`}</p>
      <p parentName="blockquote">{`"Well," they say, "look at this function. It is two pages long! None of this
stuff belongs in there! I don’t know what half of these API calls are for."`}</p>
      <p parentName="blockquote">{`[...]`}{` Old code has been used. It has been tested. Lots of bugs have been
found, and they’ve been fixed. There’s nothing wrong with it. `}{`[...]`}{`> `}</p>
      <p parentName="blockquote">{`Back to that two page function. Yes, I know, it’s just a simple function to
display a window, but it has grown little hairs and stuff on it and nobody
knows why. Well, I’ll tell you why: those are bug fixes. One of them fixes
that bug that Nancy had when she tried to install the thing on a computer that
didn’t have Internet Explorer. Another one fixes that bug that occurs in low
memory conditions. Another one fixes that bug that occurred when the file is
on a floppy disk and the user yanks out the disk in the middle. That
LoadLibrary call is ugly but it makes the code work on old versions of Windows
95.`}</p>
    </blockquote>
    <p><strong parentName="p">{`This is to say: "nasty" code will probably do things you don't think you need
until you lose them.`}</strong>{` You're not a bad person for wanting to hit your skull
against a wall trying to understand old code, but if you let an `}<em parentName="p">{`ick`}</em>{`
reaction slow you down, you're playing yourself. It's a job! Do the work! Your
customers are counting on it.`}</p>
    <script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script>
    <blockquote class="twitter-tweet" data-dnt="true"><p lang="en" dir="ltr">Becoming a software engineer because you love coding is a lot like becoming a butcher because you love animals.</p>&mdash; saurya (@Saurya) <a href="https://twitter.com/Saurya/status/1121188147826573312?ref_src=twsrc%5Etfw">April 24, 2019</a></blockquote>
    <h2>{`"Bad code," as a phrase, doesn't mean anything`}</h2>
    <p>{`Last namechecking comes from this wonderful thread by `}<a parentName="p" {...{
        "href": "https://programmingisterrible.com"
      }}>{`tef`}</a>{`, who also wrote
the hilarious (and short) `}<a parentName="p" {...{
        "href": "https://programmingisterrible.com/post/65781074112/devils-dictionary-of-programming"
      }}><em parentName="a">{`Devil's Dictionary of Programming`}</em></a>{`. He has the
full thread `}<a parentName="p" {...{
        "href": "https://twitter.com/tef_ebooks/status/1195706438645141504"
      }}>{`here`}</a>{` (and a related one `}<a parentName="p" {...{
        "href": "https://twitter.com/tef_ebooks/status/1540061252155744256"
      }}>{`here`}</a>{`), I'm cherry-picking some
relevant tweets:`}</p>
    <blockquote class="twitter-tweet" data-conversation="none" data-dnt="true"><p lang="en" dir="ltr">simple and complex are measures of how your understanding of the problem maps to the program, at the best of times</p>&mdash; tef (@tef_ebooks) <a href="https://twitter.com/tef_ebooks/status/1195706439593009153?ref_src=twsrc%5Etfw">November 16, 2019</a></blockquote>
    <blockquote class="twitter-tweet" data-conversation="none" data-dnt="true"><p lang="en" dir="ltr">a lot of the properties we seek in code—simple, approachable, malleable, debuggable—are emergent properties, often accidents</p>&mdash; tef (@tef_ebooks) <a href="https://twitter.com/tef_ebooks/status/1195706440431915009?ref_src=twsrc%5Etfw">November 16, 2019</a></blockquote>
    <blockquote class="twitter-tweet" data-conversation="none" data-dnt="true"><p lang="en" dir="ltr">a lot of the time, code ends up looking that way because it has to—we call it an abstraction when we like it, and indirection when we don&#39;t</p>&mdash; tef (@tef_ebooks) <a href="https://twitter.com/tef_ebooks/status/1195706441329512448?ref_src=twsrc%5Etfw">November 16, 2019</a></blockquote>
    <blockquote class="twitter-tweet" data-conversation="none" data-dnt="true"><p lang="en" dir="ltr">don&#39;t talk about complex. talk about what needs you have and if the code will meet them. talk about responsibilities. anything else</p>&mdash; tef (@tef_ebooks) <a href="https://twitter.com/tef_ebooks/status/1195711496476659712?ref_src=twsrc%5Etfw">November 16, 2019</a></blockquote>
    <blockquote class="twitter-tweet" data-conversation="none" data-dnt="true"><p lang="en" dir="ltr">the problem with people who eschew complexity, is that they often create something worse in their quest to eliminate it</p>&mdash; tef (@tef_ebooks) <a href="https://twitter.com/tef_ebooks/status/1195711497357471745?ref_src=twsrc%5Etfw">November 16, 2019</a></blockquote>
    <blockquote class="twitter-tweet" data-conversation="none" data-dnt="true"><p lang="en" dir="ltr">my entire career has been spent, wasted, explaining the subtle and unintuitive consequences of self described simple approaches</p>&mdash; tef (@tef_ebooks) <a href="https://twitter.com/tef_ebooks/status/1195711499043512320?ref_src=twsrc%5Etfw">November 16, 2019</a></blockquote>
    <blockquote class="twitter-tweet" data-conversation="none" data-dnt="true"><p lang="en" dir="ltr">there isn&#39;t such a thing as simple code, there&#39;s code that has no responsibilities, or code that doesn&#39;t manage its responsibilities</p>&mdash; tef (@tef_ebooks) <a href="https://twitter.com/tef_ebooks/status/1195711499873992706?ref_src=twsrc%5Etfw">November 16, 2019</a></blockquote>
    <blockquote class="twitter-tweet" data-conversation="none" data-dnt="true"><p lang="en" dir="ltr">you can write a EULA in basic english but that does not make it any more approachable—simple things combined are not simple</p>&mdash; tef (@tef_ebooks) <a href="https://twitter.com/tef_ebooks/status/1195711500775759875?ref_src=twsrc%5Etfw">November 16, 2019</a></blockquote>
    <blockquote class="twitter-tweet" data-conversation="none" data-dnt="true"><p lang="en" dir="ltr">simple, easy, clean, good—these are words that describe how the person feels about the code, often how much pride they take, not much else</p>&mdash; tef (@tef_ebooks) <a href="https://twitter.com/tef_ebooks/status/1195714655051751424?ref_src=twsrc%5Etfw">November 16, 2019</a></blockquote>
    <p>{`The EULA example is a perfect illustration: `}<em parentName="p">{`real, working`}</em>{` legal documents are
structured the way they are for a reason, as are codebases you interact with.`}</p>
    <RyuImage src={do_not_remove} alt='A picture of a dam with text overlayed to look like a code comment saying Do Not Remove' mdxType="RyuImage" />
    <RyuTextLabel mdxType="RyuTextLabel">
  (from <a href='https://theprofoundprogrammer.com/post/34818584135/text-do-not-remove-photograph-of-the'>The Profound Programmer</a>)
    </RyuTextLabel>
    <h2>{`Tech matters, but not as much as working well together`}</h2>
    <p>{`Mid-size companies are where you start turning `}<em parentName="p">{`features`}</em>{` into `}<em parentName="p">{`systems.`}</em>{` It's
when new engineers get hired to augment a previous engineer's code, without the
context of why it looks that way. It's where some people thrived when they could
keep everything in their head at once but may hit friction when that's not
possible. It's when every "single conversation" turns into a game of Telephone,
where signal loss becomes an inevitability.`}</p>
    <p>{`With all that happening, it's a ripe time for an engineer to look at something
in front of them and think "ugh, this is garbage." I write this for folks
in other mid-size companies: allowing this to fester and inform how you do your
work day-to-day is not only immature engineering, but also a quick way to
company ruin. From Sam Altman's `}<a parentName="p" {...{
        "href": "https://playbook.samaltman.com/"
      }}><em parentName="a">{`Startup Playbook`}</em></a>{`:`}</p>
    <blockquote>
      <p parentName="blockquote">{`A quick word about competitors: competitors are a startup ghost story.
First-time founders think they are what kill 99% of startups. But 99% of
startups die from suicide, not murder. Worry instead about all of your
internal problems. If you fail, it will very likely be because you failed to
make a great product and/or failed to make a great company.`}</p>
    </blockquote>
    <p><strong parentName="p">{`The hardest part of mid-size is keeping your team together and sailing the
ship as one.`}</strong>{` When you hear someone saying "oh, we're getting shanked by the
Widget team again!" or "Christ, why can't Marketing be clear about what they
want?!" your company is already dead, the doctor just hasn't called it yet.
Allowing yourself to be doomerish about a codebase is chasing a siren song:
remember how that story ends.`}</p>
    <p>{`I'm not saying "code quality is for chumps:" please think hard and carefully
about what you design and deploy! Forming opinions on code quality is an
important part of becoming a seasoned engineer, and `}<a parentName="p" {...{
        "href": "http://cleancoder.com/products"
      }}>{`some people have made a
careers`}</a>{` promising you to teach you what "good code" is (though the
`}<a parentName="p" {...{
        "href": "https://qntm.org/clean"
      }}>{`advice is`}</a>{` `}<a parentName="p" {...{
        "href": "https://www.hillelwayne.com/post/uncle-bob/"
      }}>{`often dubious`}</a>{`). Invest in your craft and form opinions on
what works and what doesn't.`}</p>
    <p><em parentName="p">{`But`}</em>{`, whichever opinions you land on, `}<strong parentName="p">{`don't let your gut reaction slow you down,
or stray you from the fact that the game is about delivering value to
customers.`}</strong>{` The code should work for your company, not the other way around;
and I hope I've persuaded you that almost every lasting codebase does this by
looking "ugly," often where it really matters. Develop taste, but not at the
expense of delivering.`}</p>
    <p>{`(if you liked this, you might also like `}<a parentName="p" {...{
        "href": "https://morepablo.com/2018/03/legacy-code.html"
      }}>{`my piece on the cultural baggage
around the phrase "legacy code"`}</a>{`)`}</p>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;