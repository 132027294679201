
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import { RyuImage, RyuTextLabel } from '@ramp/ryu'
import { YouTubeVideoEmbed } from '~/src/components/YouTubeVideoEmbed'
import alignment_quality from './alignment_quality.png'
import coin from './coin.gif'
import karim_nyse from './karim_nyse.png'
import karim_profile from './karim_profile.png'
import rocket_launch from './rocket_launch.png'
import safe_choices from './safe_choices.png'
import slope from './slope.png'
import speaking_saastr from './speaking_saastr.jpeg'
export const meta = {
  date: '2021-10-12T17:00:00.000Z',
  title: 'Making big bets in business',
  description: 'How we built Ramp by taking asymmetric risks, and why you should, too',
  authors: [{
    name: 'Karim Atiyeh',
    twitter: '@karimatiyeh',
    website: 'https://ramp.com',
    position: 'CTO & Co-Founder',
    bio: 'Karim Atiyeh is the CTO and Co-Founder of Ramp, the fastest growing spend management platform and corporate card. Launched in 2020, Ramp gives finance leaders an edge with powerful software that helps them save time & control spend before it happens. A Lebanese entrepreneur, Karim previously built and sold Y Combinator-backed Paribus to Capital One in 2016.',
    photo: karim_profile
  }]
};

const layoutProps = {
  meta
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`Let's start off with a little backstory. Born and raised in Lebanon, I came to the US to study but ended up deciding to stay long term to find work. After graduating from Harvard, I moved to New York and started doing some consulting work but very quickly found myself wanting to go back to writing code and building software, which I had been doing for years up to that point.`}</p>
    <p>{`This led me, along with our CEO `}<a parentName="p" {...{
        "href": "https://www.linkedin.com/in/eglyman/"
      }}>{`Eric Glyman`}</a>{` and `}<a parentName="p" {...{
        "href": "https://www.linkedin.com/in/genejaelee/"
      }}>{`Gene Lee`}</a>{`, to start a company in 2014 called Paribus, where we focused on helping consumers save money. The Paribus value prop was simple: whenever the price of something a customer had bought online dropped, we'd notify them and automatically give them a refund of the difference.`}</p>
    <p>{`At the peak of it, we were saving about 10 million customers millions of dollars a year on their online purchases. Three years later, after much success, we decided to sell the company to Capital One. We spent a bit of time in the card industry and finally decided to leave Capital One after two years. Fast forward a few years to 2019 and that's when we `}<a parentName="p" {...{
        "href": "https://ramp.com/blog/ramp-raises-115m-from-stripe-and-d1-to-become-nys-fastest-growing-startup-ever"
      }}>{`started Ramp`}</a>{`.`}</p>
    <RyuImage src={karim_nyse} mdxType="RyuImage" />
    <h1>{`The Ramp mission`}</h1>
    <p><a parentName="p" {...{
        "href": "https://ramp.com/blog/ramp-raises-115m-from-stripe-and-d1-to-become-nys-fastest-growing-startup-ever"
      }}>{`At Ramp`}</a>{`, we're focused on helping businesses save money, so the mission is very similar to that of Paribus, but is slightly more nuanced. We save businesses money by helping them understand their spend, control their spend, and reduce their spend in negotiations through `}<a parentName="p" {...{
        "href": "https://ramp.com/blog/real-time-expense-tracking"
      }}>{`real-time expense tracking`}</a>{`, `}<a parentName="p" {...{
        "href": "https://ramp.com/integrations"
      }}>{`integrations with popular SaaS products`}</a>{`, `}<a parentName="p" {...{
        "href": "https://ramp.com/blog/ramp-finance-automation-platform"
      }}>{`finance automation`}</a>{`, `}<a parentName="p" {...{
        "href": "https://ramp.com/blog/why-ramp-is-committed-to-cash-back"
      }}>{`1.5% cashback`}</a>{` and more. Today, Ramp is a $4 billion dollar business. We've raised about $600 million dollars in outside funding, have grown to over 170 employees and counting, and are helping more than 2,000 businesses save money on their spend.`}</p>
    <p>{`Along the way, we've experienced some amazing growth. It's incredible to think about: in the time it took us to build and sell Paribus, we were able to achieve an outcome that was about a hundred times the size and in two years with Ramp.`}</p>
    <p>{`How did we do this? One of the unique ways in how we approached growth was the way we scoped bets. How do we look for and make bets that have the potential for a very high magnitude of impact as opposed to playing it safe and trying to minimize risk?`}</p>
    <blockquote>
      <p parentName="blockquote">{`I'm sure you've heard this stat before: when it comes to startups, 90% of them don't make it.`}</p>
    </blockquote>
    <p>{`It's like a plane is crashing and you're trying to rebuild it before gravity pulls you down. In business, you only have a limited amount of time to make a couple of smart bets. You want to focus on the ones that have the biggest potential.`}</p>
    <RyuImage src={rocket_launch} mdxType="RyuImage" />
    <h2>{`Asymmetric risks and outcomes`}</h2>
    <p>{`Instead of asking people for advice and looking for the average of all the advice you get, you should probably be taking bigger, bolder bets.`}</p>
    <p>{`If the average is failure, you don't want to implement average advice. The way we refer to this concept at Ramp is asymmetric outcome.`}</p>
    <p>{`So what is an asymmetric outcome? What are asymmetric risks? If you've played poker, I'm sure you’ve been in a situation where you've drawn a pretty bad hand and you say to yourself, “Well, my hand is not that great. But there's enough in the pot, so I might as well take a risk.” This is exactly what asymmetric risk is. Sometimes you're not dealt a very good hand, but the potential reward at the end is enticing, so you might as well just try and give it a shot.`}</p>
    <RyuImage src={coin} mdxType="RyuImage" />
    <p>{`Another analogy is if you were playing a game where you're flipping a coin and, in the case of heads, you win a thousand dollars, but if you get tails, you lose a thousand dollars. In this case, the outcomes are perfectly symmetrical. So if your goal was to play that game until you earn $100,000 dollars, you would have to play at least a hundred times. And that's if you're extremely lucky.`}</p>
    <p>{`Now, imagine a game where if you flip heads you win $100,000, but if you flip tails you lose $10,000. That's a much better game to play. The outcomes are asymmetrical, but there's a catch. You need to be able to lose $10,000 to be able to play.`}</p>
    <p>{`You want to look for outcomes where, in the case of success, you have the potential for a 10x payoff. On the other hand, if you fail, maybe it's a 1x loss. You don't want to limit your downside but you want to maximize your upside.`}</p>
    <p>{`You can do this across different aspects of building and scaling your company:`}</p>
    <ul>
      <li parentName="ul">{`Choosing your problem space`}</li>
      <li parentName="ul">{`Hiring your early employees`}</li>
      <li parentName="ul">{`Picking your vendors and partners`}</li>
      <li parentName="ul">{`Building a product and fundraising`}</li>
    </ul>
    <h3>{`Choosing your problem space`}</h3>
    <p>{`If you're trying to build a company that launches rockets into space, it's hard to do that on $1 million of seed funding. But if you're able to raise more money, you're able to go after bigger problem areas where the barriers to entry are high but the outcomes are massive.`}</p>
    <p>{`Take banks for example. It's no coincidence that many of the most respected financial institutions are over a hundred years old. Amex, who I would say is the incumbent in our space, is 171 years old. At this point, in financial services, there are high barriers to entry. You need to be able to convince banks to work with you, card manufacturers to make cards for you, and so many other things just to get off the ground.`}</p>
    <p>{`So the fact that we were second-time founders with Ramp allowed us to raise an outsized seed fund. As a result, we decided to go after a bigger space, where it's harder to gain your footing. The same applies to other businesses: the more upfront funding you acquire, the bigger bets you can make. If you want to compete in a space with established players, you have to be willing to jump over the high barriers to entry.`}</p>
    <h3>{`Hiring your team`}</h3>
    <p>{`Generally, advice on hiring goes something like this: create a checklist of competencies you're looking for and mark them off one by one, trying to make sure that all of your candidates check all the boxes.`}</p>
    <p>{`The problem is most people who are exceptional at what they do tend to be very spiky. They don't have an evenly distributed skill set. This approach makes it very easy to find flaws and disqualify candidates. On the other hand, it also makes it very hard to find people who have 10x potential and can grow with you.`}</p>
    <p>{`At Ramp, we were lucky to have worked with great interns early on. One of them had sold his company to Apple when he was a freshman at Stanford. He was exceptional at writing iOS code but he had zero fintech experience. The other was extremely quick at building software but he also had no fintech experience and lacked the communication skills of a more experienced engineer.`}</p>
    <p>{`But, despite conventional wisdom, these are the types of people that we chose to hire early on, not because they had the experience and were the lowest bets, but because they had the slope that we were looking for. When you hire people like these, they scale with you and the company, making it much easier to grow your company and product.`}</p>
    <RyuImage src={slope} mdxType="RyuImage" />
    <h3>{`Picking your vendors`}</h3>
    <p>{`The same applies to vendors. Of course, if you want to limit your downside, no one's going to blame you. But the problem with making the really obvious, safe choice is you miss out on opportunities to work with up-and-coming vendors that can really alter the trajectory of your company.`}</p>
    <p>{`If everything works out when you're in an early stage, the cost of having gone with an up-and-coming vendor is very small. On the other hand, if things don't work out, the switching costs are usually manageable and you have two or three accounts to replace. So why not give it a shot?`}</p>
    <p>{`Shopify and Lyft, two giants in their respective spaces, made bets on Stripe very early on. At the time, it was a very non-obvious choice. Stripe was a small company, but they had something that they did very well: they made it very easy for developers to get started.`}</p>
    <p>{`At Ramp, we thought that one of our competitive advantages was speed. So when we looked at issue tracking software that was available to us, we ended up going with `}<a parentName="p" {...{
        "href": "https://linear.app/"
      }}>{`Linear`}</a>{`. They didn't have all the features that JIRA had, but they allowed us to move quickly and that advantage compounded over time.`}</p>
    <p>{`When you're working with scrappy, early-stage vendors, not only are you able to build relationships, but you can help shape their product vision. In essence, you become invested in each other's success. So it makes things a lot smoother and allows you to move a lot faster.`}</p>
    <h3>{`Building product`}</h3>
    <p>{`No one wants the faster horse—people want a car. If you keep listening `}<em parentName="p">{`only to`}</em>{` solutions that your customers propose to you, you're likely not going to end up with that 10x product that's going to alter the trajectory of your company.`}</p>
    <RyuImage src={safe_choices} mdxType="RyuImage" />
    <p>{`Take the example of the remotes above. With the one on the left, maybe you went to your customers and asked them which buttons they needed. But if you make big, bold bets and listen to your customers’ `}<em parentName="p">{`problems`}</em>{`, not their `}<em parentName="p">{`solutions`}</em>{`, you're able to achieve big leaps and let your product actually help you win in the market. That’s how you get a result like the remote on the right.`}</p>
    <h3>{`Fundraising and selecting investors`}</h3>
    <p>{`Meeting investors and fundraising is part of the startup process and getting it right can be a huge boost, especially early on. But it's easy to get drawn in by the headlines of large rounds and think that price is everything. In reality, price is almost never a good indicator of how well a fundraising round went. Finding reputable investors who give you clean terms gives you the best potential for asymmetric outcomes.`}</p>
    <p>{`There's a lot that goes into fundraising, and the terms that you raise at are extremely important, particularly when you're raising your first institutional round. If you're able to attract a reputable investor early on with clean terms, you avoid things like liquidation preferences, weird covenants, or giving up too many board seats early on. This kind of setup makes every other fundraising conversation down the line much easier.`}</p>
    <p>{`Reputable investors also help you attract angel investors, who are likely to turn into beta customers for you. In turn, not only are they giving you the capital to grow, they’re also providing some important feedback to make your product better.`}</p>
    <RyuImage src={alignment_quality} mdxType="RyuImage" />
    <p>{`So instead of focusing on optimizing that first fundraising round, you want to think about all the other rounds you're going to have to raise in the future, especially if you're trying to go after a big outcome.`}</p>
    <h3>{`Negative asymmetric outcomes`}</h3>
    <p>{`But in some cases there is potential for negative asymmetric risk. And this type of risk tends to grow as your customer base grows. At Ramp, we give people credit. We give customers the ability to spend on our cards and they pay us at the end of the month. But our business model is such that our revenues are about 1% of what we lend out.`}</p>
    <p>{`So for every mistake that we make, we need one hundred customers to pay us back. This is an area where we decided to not take any risk because the cost of making a mistake is a hundred times bigger than the reward if we get it right.`}</p>
    <p>{`So as a business owner you should think about the risks that would be detrimental to your company and avoid those areas. Figure out the areas where you can take a lot of risk and make these big bold bets and isolate them from the ones where you shouldn't be.`}</p>
    <RyuImage src={speaking_saastr} mdxType="RyuImage" />
    <h1>{`Don’t be afraid to take chances`}</h1>
    <p>{`In the end, bigger bets don't have to be scary. In reality, they tend to be less risky, especially if you look for opportunities to have an asymmetric outcome.`}</p>
    <p>{`If you want to learn more about how we built Ramp using asymmetric outcomes, and why I highly recommend you do, too, you can watch my SaaStr 2021 keynote below.`}</p>
    <YouTubeVideoEmbed src='https://www.youtube.com/embed/uisiczW5Uf8?start=970' mdxType="YouTubeVideoEmbed" />
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;