import React, { FunctionComponent } from 'react'
import { RyuBreak, withRyuErrorBoundary } from '@ramp/ryu'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { BlogPost } from '~/src/types'
import { ReceiptBody, ReceiptCardAuthor, ReceiptCardDashLine } from '~/src/components/ReceiptCard'
import { NeobrutalismButton } from '~/src/components/NeobrutalismButton'
import { PRIMARY_COLOR, RYU_SPACING } from '~/src/styles/constants'

type Props = {
  blogPost: BlogPost
}

export const BlogPostPreviewCardMobile: FunctionComponent<Props> = withRyuErrorBoundary(
  ({ blogPost }) => {
    const { path, title, description, authors } = blogPost

    return (
      <Link to={path}>
        <Container>
          <Content>
            <div>
              <ReceiptBody.p size='l' textAlign='center'>
                {title}
              </ReceiptBody.p>

              <RyuBreak />

              <ReceiptBody.p size='s' textAlign='left'>
                {description}
              </ReceiptBody.p>
            </div>
            <RyuBreak />
            <div>
              <ReceiptCardDashLine />

              <RyuBreak />

              <ReceiptCardAuthor disableLink={true} authors={authors} />
            </div>
          </Content>
        </Container>
      </Link>
    )
  }
)

const Container = styled(NeobrutalismButton)`
  width: 100%;

  &:hover {
    background: transparent;
    color: ${PRIMARY_COLOR} !important;
  }
`

const Content = styled.div`
  top: 0;
  left: 0;
  z-index: 3;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  padding: ${RYU_SPACING[2]};
`
