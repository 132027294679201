import { RyuBreak, RyuFlex, withRyuErrorBoundary } from '@ramp/ryu'
import React, { FunctionComponent } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { BlogPost } from '~/src/types'
import {
  ReceiptBody,
  ReceiptCard,
  ReceiptCardAuthor,
  ReceiptCardDashLine,
  ReceiptCardDate,
} from '../../../components/ReceiptCard'
import { HOME_BLOG_CARD_HEIGHT, RYU_SPACING } from '~/src/styles/constants'

type Props = {
  blogPost: BlogPost
  index: number
}

export const BlogPostPreviewCardDesktop: FunctionComponent<Props> = withRyuErrorBoundary(
  ({ blogPost, index }) => {
    const { path, title, description, date, authors } = blogPost

    return (
      <UnstyledLink to={path}>
        <BlogPostReceiptCard
          variant={index % 2 === 0 ? 'default' : 'more'}
          width='320px'
          height={`${HOME_BLOG_CARD_HEIGHT}px`}
          isWrinkleOnHover={true}
        >
          <CardContainer>
            <div>
              <ReceiptBody.p size='l' textWrap='balance' textAlign='center'>
                {title}
              </ReceiptBody.p>

              <RyuBreak />

              <RyuFlex justifyContent='center'>
                <ReceiptCardDate date={date} />
              </RyuFlex>

              <RyuBreak size='l' />

              <ReceiptBody.p size='s'>{description}</ReceiptBody.p>
            </div>

            <RyuBreak />

            <div>
              <ReceiptCardDashLine />

              <RyuBreak />

              <ReceiptCardAuthor disableLink={true} authors={authors} />
            </div>
          </CardContainer>
        </BlogPostReceiptCard>
      </UnstyledLink>
    )
  }
)

const UnstyledLink = styled(Link)`
  text-decoration: none;
  color: inherit;
`

const BlogPostReceiptCard = styled(ReceiptCard)`
  position: relative;
  text-decoration: none;
  color: inherit;

  transition: transform 0.2s ease-out;

  &:hover {
    transform: translateY(-${RYU_SPACING[5]});
  }
`

const CardContainer = styled.div`
  top: 0;
  left: 0;
  z-index: 3;
  font-size: 1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  padding: ${RYU_SPACING[8]};
`
