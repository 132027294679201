import React, { FunctionComponent } from 'react'
import { MDXProvider, MDXProviderComponentsProp } from '@mdx-js/react'
import { RyuCode, RyuCodeBlock, RyuCodeLanguage, RyuText } from '@ramp/ryu'

import { MarkdownAnchorAdapter } from '~/src/components/MarkdownAnchorAdapter'
import { ReceiptHeader } from '../ReceiptCard'

const MdxPreAdapter: FunctionComponent<any> = (props) => {
  const { className, children } = props.children.props

  const languageMatcher = /language-(\w+)/
  const languageMatch = languageMatcher.exec(className ?? '')

  const language = languageMatch ? (languageMatch[1] as RyuCodeLanguage) : 'text'

  const childrenString = String(children).replace(/\n$/, '')

  return <RyuCodeBlock language={language}>{childrenString}</RyuCodeBlock>
}

const Heading3: FunctionComponent = ({ children, ...props }) => (
  <ReceiptHeader.h3 {...props}>{children}</ReceiptHeader.h3>
)
const Heading4: FunctionComponent = ({ children, ...props }) => (
  <ReceiptHeader.h4 {...props}>{children}</ReceiptHeader.h4>
)
const Heading5: FunctionComponent = ({ children, ...props }) => (
  <ReceiptHeader.h5 {...props}>{children}</ReceiptHeader.h5>
)
const Heading6: FunctionComponent = ({ children, ...props }) => (
  <ReceiptHeader.h6 {...props}>{children}</ReceiptHeader.h6>
)

const mdxRyuAdapter: MDXProviderComponentsProp = {
  wrapper: RyuText,
  pre: MdxPreAdapter,
  code: RyuCode,
  a: MarkdownAnchorAdapter,

  // ryu headings are super large, so we tone it down for Markdown content
  h1: Heading3,
  h2: Heading4,
  h3: Heading5,
  h4: Heading6,
  h5: Heading6,
  h6: Heading6,
}

export const MdxAdapter: FunctionComponent = ({ children }) => {
  return <MDXProvider components={mdxRyuAdapter}>{children}</MDXProvider>
}
