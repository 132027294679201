import React from 'react'
import styled, { css } from 'styled-components'
import { PRIMARY_COLOR } from '../styles/constants'

const neobrutalismStyle = css`
  color: ${PRIMARY_COLOR};
  border-top: 1px solid ${PRIMARY_COLOR};
  border-right: 1px solid ${PRIMARY_COLOR};
  border-bottom: 2px solid ${PRIMARY_COLOR};
  border-left: 1px solid ${PRIMARY_COLOR};
  border-radius: 4px;
  background: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(25px);

  text-decoration: none;
  display: inline-block;

  font-family: Menlo;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 115%;
  letter-spacing: -0.28px;

  &:focus,
  &:focus-visible {
    outline: 1px solid ${PRIMARY_COLOR};
    outline-offset: -1px;
  }

  padding: 8px;
`

export const NeobrutalismBorder = styled.div`
  ${neobrutalismStyle}
`

const neobrutalismButtonStyle = css`
  ${neobrutalismStyle}
  transition: border-bottom 0.3s ease;
  cursor: pointer;
  background: rgba(255, 255, 255, 0.6);
  backdrop-filter: blur(10px);
  text-transform: uppercase;

  // Need to override default Ryu styles
  text-decoration: none !important;
  color: ${PRIMARY_COLOR} !important;
  outline: none !important;

  &:hover {
    border-top: 1px solid ${PRIMARY_COLOR};
    border-right: 1px solid ${PRIMARY_COLOR};
    border-bottom: 2px solid ${PRIMARY_COLOR};
    border-left: 1px solid ${PRIMARY_COLOR};
    background: ${PRIMARY_COLOR};
    outline: none;

    // Need to override default Ryu styles
    color: white !important;
  }
`

const NeobrutalismButtonStyleImpl = styled.button`
  ${neobrutalismButtonStyle}
`
const NeobrutalismAnchorStyleImpl = styled.a`
  ${neobrutalismButtonStyle}
`

type ButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement>
type AnchorProps = React.AnchorHTMLAttributes<HTMLAnchorElement>

export function NeobrutalismButton({
  as = 'button',
  children,
  ...props
}: {
  as?: 'button' | 'a'
  children: React.ReactNode
} & (ButtonProps | AnchorProps)) {
  return as === 'button' ? (
    <NeobrutalismButtonStyleImpl {...(props as ButtonProps)}>{children}</NeobrutalismButtonStyleImpl>
  ) : (
    <NeobrutalismAnchorStyleImpl {...(props as AnchorProps)}>{children}</NeobrutalismAnchorStyleImpl>
  )
}
