import grunge from '~/src/assets/grunge.png'
import styled, { css } from 'styled-components'

const seededRandom = (seed: string, index: number) => {
  const hash = seed?.split('').reduce((acc, char, i) => {
    return char.charCodeAt(0) + ((acc << 5) - acc + i)
  }, index)
  return -50 + (Math.abs(hash) % 100)
}

interface GrungeHighlightProps {
  children: string
}
/**
 * Based off of the text, this component will generate a random offset seed for the background image
 *
 * Thus, each text will have the same grunge every time, but each grunge will be slightly different
 */
export const GrungeHighlight = styled.span<GrungeHighlightProps>`
  ${({ children }) => css`
    background-image: url(${grunge}), url(${grunge}), url(${grunge});
    background-size: 400px, 360px, 440px;
    background-repeat: repeat;
    background-position: ${seededRandom(children, 1)}px ${seededRandom(children, 2)}px,
      ${seededRandom(children, 3)}px ${seededRandom(children, 4)}px,
      ${seededRandom(children, 5)}px ${seededRandom(children, 6)}px;
    background-blend-mode: soft-light;
    color: white;
    padding: 0.1em 0.2em;
    display: inline-block;
    position: relative;
  `}
`
