import { RefObject, useEffect } from 'react'

export type IntersectionObserverParams = {
  tolerance: number
} & Pick<IntersectionObserverInit, 'root' | 'threshold'>

export type UseIntersectionObserverOptions = {
  key?: string
  tolerance?: number
  disabled?: boolean
} & Pick<IntersectionObserverInit, 'root' | 'threshold'>

export type OnIntersectionContext = {
  rect: DOMRectReadOnly
  visible: boolean
  intersectionRatio: IntersectionObserverEntry['intersectionRatio']
}

export function useIntersectionObserver(
  refs: RefObject<SVGElement | HTMLElement>[],
  onIntersection: (ref: RefObject<HTMLElement | SVGElement>, context: OnIntersectionContext) => void,
  {
    disabled = false,
    tolerance = 0,
    root = null,
    threshold = [0],
    key,
  }: UseIntersectionObserverOptions = {}
) {
  useEffect(() => {
    if (disabled) {
      return
    }

    const observers = refs.map(getRefObserver)

    return () => {
      for (const observer of observers) {
        observer?.disconnect()
      }
    }

    function getRefObserver(ref: RefObject<HTMLElement | SVGElement>): IntersectionObserver | null {
      const element = ref.current

      if (element === null) {
        return null
      }

      const observerOptions = getIntersectionObserverOptions({ tolerance, root, threshold })

      const observer = new IntersectionObserver((entries) => {
        // When `ref.current` changes, `IntersectionObserver` gets confused and creates a new entry.
        // This is a way of making sure we only keep the latest version observed at a time.
        while (entries.length > 1) {
          const entry = entries.shift()

          if (entry?.target) {
            observer.unobserve(entry.target)
          }
        }

        const [{ boundingClientRect: rect, isIntersecting: visible, intersectionRatio }] = entries

        onIntersection(ref, { rect, visible, intersectionRatio })
      }, observerOptions)

      observer.observe(element)

      return observer
    }
  }, [key, refs, disabled, tolerance, root, threshold, onIntersection])
}

function getIntersectionObserverOptions({
  root,
  threshold,
  tolerance,
}: IntersectionObserverParams): IntersectionObserverInit {
  const rootMargin = `${tolerance}px ${tolerance}px ${tolerance}px ${tolerance}px`

  return {
    threshold,
    root,
    rootMargin,
  }
}
