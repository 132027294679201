import React, { Fragment } from 'react'
import { RyuBreak, RyuFlex, RyuIcon, RyuPad } from '@ramp/ryu'
import { BlogPostPreviewCardDesktop } from '~/src/routes/BlogHomeRoute/components/BlogPostPreviewCardDesktop'
import { RegularPageContainer } from '~/src/components/RegularPageContainer'
import { TitledContent } from '~/src/components/TitledContent'
import { blogPosts } from '~/src/utils/blog'
import styled from 'styled-components'

import { DayOneFooter } from './components/DayOneFooter'
import { BLOG_HOME_BREAKPOINTS, HOME_BLOG_CARD_HEIGHT } from '../../styles/constants'
import { BlogTitle } from './components/BlogTitle'
import { BlogShowOnDesktop } from '../../components/BlogShowOnDesktop'
import { BlogShowOnMobile } from '../../components/BlogShowOnMobile'
import { BlogPostPreviewCardMobile } from './components/BlogPostPreviewCardMobile'
import { NeobrutalismButton } from '~/src/components/NeobrutalismButton'
import { ReceiptBody } from '~/src/components/ReceiptCard'

// Temp variable, going to enable this for the easter egg action bar
const TEMP_IS_CARD_OVERLAP_ENABLED = true

// Card positions
const DESKTOP_X_OFFSET = 45
const DESKTOP_ROWS = 3
const DESKTOP_Y_OFFSET = -40
const TABLET_ROWS = 2
const TABLET_X_OFFSET = 10

export const BlogHomeRoute = () => {
  const [sortAscending, setSortAscending] = React.useState(false)

  const sortedBlogPosts = React.useMemo(() => {
    return [...blogPosts].sort((a, b) => {
      const comparison = a.date.getTime() - b.date.getTime()
      return sortAscending ? comparison : -comparison
    })
  }, [sortAscending])

  return (
    <TitledContent title='Blog posts'>
      <RegularPageContainer>
        <BlogTitle />

        <RyuBreak size='xl' />

        <SubHeader>
          <FilterContainer>
            <ReceiptBody.div size='s' uppercase={true}>
              Sort
            </ReceiptBody.div>
            <NeobrutalismButton onClick={() => setSortAscending(!sortAscending)}>
              <RyuFlex alignItems='center' justifyContent='center' marginSize='xs'>
                Date <RyuIcon type={sortAscending ? 'arrow-up' : 'arrow-down'} />
              </RyuFlex>
            </NeobrutalismButton>
          </FilterContainer>
        </SubHeader>

        <RyuBreak size='xl' />
        <BlogShowOnDesktop>
          <RyuBreak size='xl' />
        </BlogShowOnDesktop>

        <GridContainer>
          {sortedBlogPosts.map((blogPost, index) => (
            <Fragment key={blogPost.path}>
              <BlogShowOnDesktop>
                <CardContainer $index={index}>
                  <OverlappingCard
                    $index={index}
                    $isOverlapEnabled={TEMP_IS_CARD_OVERLAP_ENABLED}
                    $title={blogPost.title}
                  >
                    <BlogPostPreviewCardDesktop blogPost={blogPost} index={index} />
                  </OverlappingCard>
                  <RyuBreak />
                </CardContainer>
              </BlogShowOnDesktop>
              <BlogShowOnMobile>
                <BlogPostPreviewCardMobile blogPost={blogPost} />
              </BlogShowOnMobile>
            </Fragment>
          ))}
        </GridContainer>

        <RyuBreak size='xxl' />
        <RyuBreak size='xxl' />

        <DayOneFooter />
      </RegularPageContainer>
    </TitledContent>
  )
}

function getYOffset(index: number) {
  const row = Math.floor(index / DESKTOP_ROWS)
  return row * DESKTOP_Y_OFFSET
}

function getXDesktopOffset(index: number) {
  if (index % DESKTOP_ROWS === 0) {
    return DESKTOP_X_OFFSET
  } else if (index % DESKTOP_ROWS === 1) {
    return 0
  }
  return -DESKTOP_X_OFFSET
}

function getXTabletOffset(index: number) {
  if (index % TABLET_ROWS === 0) {
    return TABLET_X_OFFSET
  }
  return -TABLET_X_OFFSET
}

function getDesktopRotation(index: number) {
  const rowOffsets = [
    [-2, 3, 0], // First row
    [3, 0, -3], // Second row
    [0, -3, 2], // Third row
  ]

  const row = Math.floor(index / DESKTOP_ROWS)
  const col = index % DESKTOP_ROWS

  return rowOffsets[row % DESKTOP_ROWS][col]
}

function getTabletRotation(index: number) {
  const rowOffsets = [
    [-2, 2], // First row
    [2, 0], // Second row
    [0, -2], // Third row
  ]

  const row = Math.floor(index / TABLET_ROWS)
  const col = index % TABLET_ROWS

  return rowOffsets[row % TABLET_ROWS][col]
}

function getCardPosition(index: number) {
  if (index % TABLET_ROWS === 0) {
    return 'flex-end'
  }
  return 'flex-start'
}

function getCardRowHeight({ numPerRow, yOffset }: { numPerRow: number; yOffset: number }) {
  const rows = Math.floor(blogPosts.length / numPerRow)

  const totalYOffset = rows * yOffset

  return rows * HOME_BLOG_CARD_HEIGHT - totalYOffset
}

const CardContainer = styled.div<{ $index: number }>`
  display: flex;
  justify-content: center;

  @media (min-width: ${BLOG_HOME_BREAKPOINTS.tablet}) {
    justify-content: ${(props) => getCardPosition(props.$index)};
  }
`

const SubHeader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`

const FilterContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 16px;
  width: 100%;
  position: absolute;
  top: 0px;

  @media (max-width: ${BLOG_HOME_BREAKPOINTS.tablet}) {
    display: none;
  }

  @media (min-width: ${BLOG_HOME_BREAKPOINTS.tablet}) {
    right: ${TABLET_X_OFFSET}px;
  }

  @media (min-width: ${BLOG_HOME_BREAKPOINTS.desktop}) {
    right: ${DESKTOP_X_OFFSET}px;
  }
`

const OverlappingCard = styled(RyuPad)<{
  $index: number
  $isOverlapEnabled?: boolean
  $title: string
}>`
  z-index: ${(props) => props.$index};

  @media (min-width: ${BLOG_HOME_BREAKPOINTS.tablet}) {
    transform: ${(props) =>
      !props.$isOverlapEnabled
        ? 'none'
        : `rotate(${getTabletRotation(props.$index)}deg)
           translateX(${getXTabletOffset(props.$index)}px)
           translateY(${getYOffset(props.$index)}px)`};
  }

  @media (min-width: ${BLOG_HOME_BREAKPOINTS.desktop}) {
    transform: ${(props) =>
      !props.$isOverlapEnabled
        ? 'none'
        : `rotate(${getDesktopRotation(props.$index)}deg)
           translateX(${getXDesktopOffset(props.$index)}px)
           translateY(${getYOffset(props.$index)}px)`};
  }
`

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 24px;

  @media (min-width: ${BLOG_HOME_BREAKPOINTS.tablet}) {
    grid-template-columns: repeat(2, 1fr);
    height: ${getCardRowHeight({ numPerRow: TABLET_ROWS, yOffset: DESKTOP_Y_OFFSET })}px;
  }

  @media (min-width: ${BLOG_HOME_BREAKPOINTS.desktop}) {
    grid-template-columns: repeat(3, 1fr);
    height: ${getCardRowHeight({ numPerRow: DESKTOP_ROWS, yOffset: DESKTOP_Y_OFFSET })}px;
  }
`
