import React, { createContext, useContext, useState, ReactNode } from 'react'

interface EasterEggs {
  isNavbarApplyToRampAnimation: boolean
}

interface EasterEggsContextType {
  easterEggs: EasterEggs
  toggleEasterEgg: (key: keyof EasterEggs) => void
}

const EasterEggsContext = createContext<EasterEggsContextType | undefined>(undefined)

export function EasterEggsProvider({ children }: { children: ReactNode }) {
  const [easterEggs, setEasterEggs] = useState<EasterEggs>({
    isNavbarApplyToRampAnimation: false,
  })

  const toggleEasterEgg = (key: keyof EasterEggs) => {
    setEasterEggs((prev) => ({
      ...prev,
      [key]: !prev[key],
    }))
  }

  return (
    <EasterEggsContext.Provider value={{ easterEggs, toggleEasterEgg }}>
      {children}
    </EasterEggsContext.Provider>
  )
}

export function useEasterEggs() {
  const context = useContext(EasterEggsContext)
  if (!context) {
    throw new Error('useEasterEggs must be used within an EasterEggsProvider')
  }
  return context
}
