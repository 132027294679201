import React, { FunctionComponent, useEffect } from 'react'
import { RyuFlex, RyuIcon, RyuText, RyuTooltip, withRyuErrorBoundary } from '@ramp/ryu'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import { NeobrutalismButton } from '../NeobrutalismButton'
import { CAREERS_URL } from '~/src/urls'
import { useEasterEggs } from '../EasterEggsContext'
import { BLOG_CONTENT_MAX_WIDTH, BLOG_HOME_BREAKPOINTS, RYU_SPACING } from '~/src/styles/constants'

export const BlogHeader: FunctionComponent = withRyuErrorBoundary(() => {
  const [isScrolled, setIsScrolled] = React.useState(false)
  const { easterEggs, toggleEasterEgg } = useEasterEggs()

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 1 && !easterEggs.isNavbarApplyToRampAnimation) {
        toggleEasterEgg('isNavbarApplyToRampAnimation')
      }
      setIsScrolled(window.scrollY > 0)
    }
    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [easterEggs.isNavbarApplyToRampAnimation, toggleEasterEgg])

  return (
    <BlogHeaderRoot>
      <HeaderContainer>
        <RyuText color='hushed'>
          <RyuFlex alignItems='center' justifyContent='spaceBetween'>
            <UnstyledLink to='/'>
              <RyuTooltip content='Home'>
                <NeobrutalismButton aria-label='Home'>
                  <RyuIcon type='ramp' />
                </NeobrutalismButton>
              </RyuTooltip>
            </UnstyledLink>
            <RyuFlex alignItems='center' marginSize='xs'>
              <NeobrutalismButton as='a' href={CAREERS_URL} target='_blank' rel='noreferrer'>
                <RyuFlex alignItems='center' marginSize='xs' justifyContent='center'>
                  <AnimatedText>
                    <TextWrapper isScrolled={isScrolled || easterEggs.isNavbarApplyToRampAnimation}>
                      <div>We're hiring</div>
                      <div>Apply today</div>
                    </TextWrapper>
                  </AnimatedText>
                  <Arrow type='arrow-up-right' isScrolled={isScrolled} />
                </RyuFlex>
              </NeobrutalismButton>
            </RyuFlex>
          </RyuFlex>
        </RyuText>
      </HeaderContainer>
    </BlogHeaderRoot>
  )
})

const BlogHeaderRoot = styled.div`
  position: sticky;
  top: 0;
  z-index: 99999;

  @media (min-width: ${BLOG_HOME_BREAKPOINTS.desktop}) {
    padding: ${RYU_SPACING[6]} ${RYU_SPACING[10]};
  }

  @media (min-width: ${BLOG_HOME_BREAKPOINTS.tablet}) {
    padding: ${RYU_SPACING[6]} ${RYU_SPACING[6]};
  }

  padding: ${RYU_SPACING[6]} ${RYU_SPACING[4]};
`

const HeaderContainer = styled.div`
  max-width: ${BLOG_CONTENT_MAX_WIDTH}px;
  margin: 0 auto;
`

const AnimatedText = styled.div`
  position: relative;
  font-size: 14px;
  height: 14px;
  overflow: hidden;
`

const TextWrapper = styled.div<{ isScrolled: boolean }>`
  transition: transform 0.3s ease;
  transform: translateY(${(props) => (props.isScrolled ? '-50%' : '0')});
`

const Arrow = styled(RyuIcon)<{ isScrolled: boolean }>`
  transition: transform 0.3s ease;
  transform: translateX(${(props) => (props.isScrolled ? '4px' : '0')});
`

const UnstyledLink = styled(Link)`
  text-decoration: none;
  color: inherit;
`
