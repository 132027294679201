import React, { Fragment, FunctionComponent, useEffect } from 'react'
import { RyuBreak, RyuFlex, withRyuErrorBoundary, RyuGrid, RyuFlexGrow } from '@ramp/ryu'

import { BlogPost, BlogPostAuthor } from '~/src/types'
import {
  ReceiptBody,
  ReceiptCard,
  ReceiptCardAuthor,
  ReceiptCardDate,
} from '~/src/components/ReceiptCard'
import styled from 'styled-components'
import { NeobrutalismButton } from '~/src/components/NeobrutalismButton'
import { ORIGIN } from '~/src/utils/metadata'
import { BlogShowOnMobile } from '~/src/components/BlogShowOnMobile'
import { BlogShowOnDesktop } from '~/src/components/BlogShowOnDesktop'
import { BLOG_HOME_BREAKPOINTS, PRIMARY_COLOR, RYU_SPACING } from '~/src/styles/constants'

type Props = {
  blogPost: BlogPost
  disableLink?: boolean
}

const DESKTOP_ATTRIBUTION_CARD_WIDTH = 288

const DESKTOP_SIDEBAR_TITLE_SIZE = 24

const SHOW_SIDEBAR_TITLE_SCROLL_OFFSET = 200

export const BlogPostAttribution: FunctionComponent<Props> = withRyuErrorBoundary(
  ({ blogPost, disableLink }) => {
    const { authors, date, title } = blogPost
    const [isScrolled, setIsScrolled] = React.useState(false)
    const [titleHeight, setTitleHeight] = React.useState(0)

    useEffect(() => {
      const handleScroll = () => {
        const shouldShow = window.scrollY > SHOW_SIDEBAR_TITLE_SCROLL_OFFSET
        if (!shouldShow && isScrolled) {
          setTimeout(() => {
            setIsScrolled(false)
          }, 300)
        } else {
          setIsScrolled(shouldShow)
        }
      }
      window.addEventListener('scroll', handleScroll)
      return () => window.removeEventListener('scroll', handleScroll)
    }, [isScrolled])

    useEffect(() => {
      const height = measureTextHeight({
        text: title,
        containerWidth: DESKTOP_ATTRIBUTION_CARD_WIDTH,
        fontFamily: 'Menlo',
        fontSize: `${DESKTOP_SIDEBAR_TITLE_SIZE}px`,
      })
      setTitleHeight(height)
    }, [title])

    const handleShare = (platform: 'twitter' | 'linkedin') => {
      const shareUrl = `${ORIGIN}${blogPost.path}`
      const text = `Check out "${title}" by ${authors.map((a) => a.name).join(', ')}`
      const urls = {
        twitter: `https://twitter.com/intent/tweet?text=${encodeURIComponent(
          text
        )}&url=${encodeURIComponent(shareUrl)}`,
        linkedin: `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(
          shareUrl
        )}&title=${encodeURIComponent(title)}&summary=${encodeURIComponent(
          text
        )}&source=${encodeURIComponent('Ramp')}`,
      }
      window.open(urls[platform], '_blank', 'noopener,noreferrer')
    }

    const shareTwitter = (
      <NeobrutalismButton onClick={() => handleShare('twitter')}>X/Twitter</NeobrutalismButton>
    )

    const shareLinkedin = (
      <NeobrutalismButton onClick={() => handleShare('linkedin')}>LinkedIn</NeobrutalismButton>
    )

    return (
      <Fragment>
        <BlogShowOnMobile>
          <MobileAttributionCard>
            <Attribution date={date} authors={authors} />
          </MobileAttributionCard>
          <RyuBreak size='l' />
          <RyuFlex marginSize='xs'>
            <ReceiptBody.div size='s' uppercase={true}>
              Share on
            </ReceiptBody.div>
            <RyuFlexGrow>{shareTwitter}</RyuFlexGrow>
            <RyuFlexGrow>{shareLinkedin}</RyuFlexGrow>
          </RyuFlex>
        </BlogShowOnMobile>
        <DesktopSidebar>
          <DesktopSidebarContent>
            <SidebarTitleText $isScrolled={isScrolled} $titleHeight={titleHeight}>
              {title}
            </SidebarTitleText>
            <DesktopAttributionCard shadowVariant='subtle'>
              <Attribution date={date} authors={authors} disableLink={disableLink} />
            </DesktopAttributionCard>
            <RyuBreak size='l' />
            <ReceiptBody.div size='s' uppercase={true}>
              Share on
            </ReceiptBody.div>
            <RyuBreak size='xs' />
            <RyuGrid columns={2} marginSize='xs'>
              {shareTwitter}
              {shareLinkedin}
            </RyuGrid>
          </DesktopSidebarContent>
        </DesktopSidebar>
      </Fragment>
    )
  }
)

type AttributionProps = {
  date: Date
  authors: BlogPostAuthor[]
  disableLink?: boolean
}

function Attribution({ date, authors, disableLink }: AttributionProps) {
  return (
    <Fragment>
      <RyuFlex justifyContent='spaceBetween'>
        <ReceiptBody.div size='s' uppercase={true}>
          Date
        </ReceiptBody.div>
        <ReceiptCardDate date={date} />
      </RyuFlex>
      <RyuBreak />
      <ReceiptCardAuthor authors={authors} disableLink={disableLink} />
    </Fragment>
  )
}

const SidebarTitleText = styled.h5<{ $isScrolled: boolean; $titleHeight: number }>`
  font-family: Menlo;
  font-size: ${DESKTOP_SIDEBAR_TITLE_SIZE}px;
  font-style: normal;
  font-weight: 400;
  overflow: hidden;
  height: ${(props) => (props.$isScrolled ? `${props.$titleHeight + 16}px` : '0')};
  opacity: ${(props) => (props.$isScrolled ? '1' : '0')};
  visibility: ${(props) => (props.$isScrolled ? 'visible' : 'hidden')};
  transition: all 0.3s ease;
  display: flex;
  flex-direction: column;
  text-wrap: balance;
  text-transform: uppercase;
`

const DesktopSidebarContent = styled.div`
  position: sticky;
  top: 84px;
`

const DesktopSidebar = styled(BlogShowOnDesktop)`
  min-height: 500px;
  height: 100%;
`

const DesktopAttributionCard = styled(ReceiptCard)`
  padding: ${RYU_SPACING[6]};

  @media (min-width: ${BLOG_HOME_BREAKPOINTS.tablet}) {
    width: ${DESKTOP_ATTRIBUTION_CARD_WIDTH}px;
  }
`

const MobileAttributionCard = styled.div`
  padding: ${RYU_SPACING[4]};
  border: 1px dashed ${PRIMARY_COLOR};
  width: 100%;
`

// To animate the title, we need to measure height of text, but text can be dynamic. So, let's render it in a canvas and measure the height first
export function measureTextHeight({
  text,
  containerWidth,
  fontFamily,
  fontSize,
}: {
  text: string
  containerWidth: number
  fontFamily: string
  fontSize: string
}): number {
  const canvas = document.createElement('canvas')
  const context = canvas.getContext('2d')
  if (!context) {
    throw new Error('Failed to create canvas context')
  }

  context.font = `400 ${fontSize} ${fontFamily}`

  const words = text.split(' ')
  const lineHeightPx = parseInt(fontSize) * 1.2

  let currentLine = ''
  let totalHeight = lineHeightPx

  words.forEach((word) => {
    const testLine = currentLine ? `${currentLine} ${word}` : word
    const metrics = context.measureText(testLine)

    if (metrics.width > containerWidth) {
      currentLine = word
      totalHeight += lineHeightPx
    } else {
      currentLine = testLine
    }
  })

  return Math.ceil(totalHeight)
}
