import React, { ReactNode } from 'react'
import styled from 'styled-components'
import paperTexture1 from '~/src/assets/paper-1.png'
import paperTexture2 from '~/src/assets/paper-2.png'
import paperTexture3 from '~/src/assets/paper-3.png'
import { BlogPost } from '../types'
import { RyuFlex, RyuLink } from '@ramp/ryu'
import { getBlogDate } from '../utils/blog'
import { PRIMARY_COLOR } from '../styles/constants'

type ReceiptCardProps = {
  children: ReactNode
  variant?: 'default' | 'more'
  shadowVariant?: 'default' | 'subtle'
  height?: number | string
  maxHeight?: number | string
  minHeight?: number | string
  width?: number | string
  className?: string
  isWrinkleOnHover?: boolean
}

export const ReceiptCard = styled(
  ({
    variant = 'default',
    height,
    shadowVariant = 'default',
    maxHeight,
    minHeight,
    width,
    children,
    className,
    isWrinkleOnHover = false,
  }: ReceiptCardProps) => {
    return (
      <CardContainer
        className={className}
        $paperTexture={variant === 'default' ? paperTexture1 : paperTexture2}
        $height={height}
        $maxHeight={maxHeight}
        $minHeight={minHeight}
        $width={width}
        $isWrinkleOnHover={isWrinkleOnHover}
        $shadowVariant={shadowVariant}
      >
        {children}
      </CardContainer>
    )
  }
)``

export const ReceiptCardDate = ({ date }: { date: BlogPost['date'] }) => {
  return <ReceiptBody.div size='s'>{getBlogDate(date)}</ReceiptBody.div>
}

export const ReceiptCardAuthor = ({
  authors,
  disableLink = false,
}: {
  authors: BlogPost['authors']
  disableLink?: boolean
}) => {
  return (
    <RyuFlex justifyContent='spaceBetween' wrap={true} alignItems='flexStart'>
      <ReceiptBody.div size='s' uppercase={true}>
        {authors.length > 1 && `${authors.length}X`} Author
      </ReceiptBody.div>
      <ReceiptBody.div size='s'>
        {authors.map((author) => (
          <div key={author.name}>{getAuthorName(author)}</div>
        ))}
      </ReceiptBody.div>
    </RyuFlex>
  )

  function getAuthorName(author: BlogPost['authors'][number]) {
    return author.website && !disableLink ? (
      <RyuLink href={author.website} externalLinkIcon={false}>
        {author.name}
      </RyuLink>
    ) : (
      author.name
    )
  }
}

export const ReceiptCardDashLine = styled.div`
  border: none;
  height: 1.5px;
  width: 100%;
  background-image: linear-gradient(to right, ${PRIMARY_COLOR} 10px, transparent 10px);
  background-size: 18px 1.5px;
  background-repeat: repeat-x;
`

const CardContainer = styled.div<{
  $paperTexture: string
  $height?: number | string
  $maxHeight?: number | string
  $minHeight?: number | string
  $width?: number | string
  $isWrinkleOnHover?: boolean
  $shadowVariant?: 'default' | 'subtle'
}>`
  position: relative;
  max-height: ${({ $maxHeight }) => $maxHeight ?? undefined};
  height: ${({ $height }) => $height ?? undefined};
  width: ${({ $width }) => $width ?? undefined};
  background: #f7f7f7;

  background-image: url(${(props) => props.$paperTexture});
  background-repeat: repeat;
  background-size: cover;

  box-shadow: ${({ $shadowVariant }) =>
    $shadowVariant === 'default'
      ? '1px 4px 8px 0px rgba(0, 0, 0, 0.15), 2px 12px 24px 0px rgba(0, 0, 0, 0.1), 0px 0px 0px 1px rgba(145, 145, 123, 0.2)'
      : '1px 4px 8px 0px rgba(0, 0, 0, 0.15)'};
  transition: transform 0.05s ease-out, background-image 0.05s ease;

  &:hover {
    background-image: ${({ $isWrinkleOnHover }) =>
      $isWrinkleOnHover ? `url(${paperTexture3})` : undefined};
  }
`

const BaseHeaderStyle = styled.div`
  color: ${PRIMARY_COLOR};
  font-style: normal;
  font-weight: 400;
  letter-spacing: -2%;
  line-height: 150%;

  // Overwrites default Ryu styling
  font-family: Menlo !important;
`

export const ReceiptHeader = {
  h2: styled(BaseHeaderStyle.withComponent('h2'))`
    font-size: 48px;
    padding: 12px 0;
    @media (max-width: 768px) {
      font-size: 32px;
    }
  `,
  h3: styled(BaseHeaderStyle.withComponent('h3'))`
    font-size: 36px;
    padding: 28px 0;
    @media (max-width: 768px) {
      font-size: 28px;
    }
  `,
  h4: styled(BaseHeaderStyle.withComponent('h4'))`
    font-size: 28px;
    @media (max-width: 768px) {
      font-size: 20px;
    }
  `,
  h5: styled(BaseHeaderStyle.withComponent('h5'))`
    font-size: 24px;
    letter-spacing: -0.48px;
  `,
  h6: styled(BaseHeaderStyle.withComponent('h6'))`
    font-size: 20px;
    letter-spacing: -0.48px;
  `,
}

const BaseReceiptBodyStyle = styled.div<{
  size?: 's' | 'm' | 'l'
  uppercase?: boolean
  textAlign?: 'left' | 'center' | 'right'
  textWrap?: 'wrap' | 'nowrap' | 'balance'
  color?: 'primary' | 'hushed'
}>`
  font-family: Menlo;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: -2%;
  color: ${({ theme, color = 'primary' }) =>
    color === 'hushed' ? theme.ryuColors.hushed : theme.ryuColors.primary};
  text-transform: ${({ uppercase }) => (uppercase ? 'uppercase' : 'none')};
  text-align: ${({ textAlign }) => textAlign ?? 'left'};
  text-wrap: ${({ textWrap }) => textWrap ?? 'wrap'};

  font-size: ${({ size = 'm' }) =>
    ({
      s: '14px',
      m: '16px',
      l: '20px',
    }[size])};
`

export const ReceiptBody = {
  div: BaseReceiptBodyStyle,
  p: styled(BaseReceiptBodyStyle.withComponent('p'))``,
  span: styled(BaseReceiptBodyStyle.withComponent('span'))``,
}
