import React, { Fragment, useRef } from 'react'
import { useIntersectionObserver } from '~/src/hooks/useInteractionObserver'

const DECODE_CHARS = '!@#$%^&*+=-_?/'
const DECODE_STEPS = 3
const STEP_DURATION = 50

function createDecodeEffect({
  setDisplayChars,
  stepDuration = STEP_DURATION,
  decodeSteps = DECODE_STEPS,
}: {
  setDisplayChars: React.Dispatch<React.SetStateAction<string[]>>
  stepDuration?: number
  decodeSteps?: number
}) {
  return (index: number, finalChar: string) => {
    let step = 0

    const interval = setInterval(() => {
      if (step < decodeSteps) {
        setDisplayChars((prev) => {
          const next = [...prev]
          next[index] = DECODE_CHARS[Math.floor(Math.random() * DECODE_CHARS.length)]
          return next
        })
        step++
      } else {
        setDisplayChars((prev) => {
          const next = [...prev]
          next[index] = finalChar
          return next
        })
        clearInterval(interval)
      }
    }, stepDuration)
  }
}

export function HoverRevealText({ text }: { text: string }) {
  const [displayChars, setDisplayChars] = React.useState<string[]>(
    text.split('').map((char) => (/[a-zA-Z0-9]/.test(char) ? '*' : char))
  )

  const decodeChar = createDecodeEffect({ setDisplayChars })

  return (
    <Fragment>
      {text.split('').map((char, i) => (
        <span key={i} onMouseEnter={() => decodeChar(i, char)}>
          {displayChars[i]}
        </span>
      ))}
    </Fragment>
  )
}

export function ViewRevealText({ text }: { text: string }) {
  const textRef = useRef<HTMLDivElement>(null)
  const [displayChars, setDisplayChars] = React.useState<string[]>(
    text.split('').map((char) => (/[a-zA-Z0-9]/.test(char) ? '*' : char))
  )
  const [hasRevealed, setHasRevealed] = React.useState(false)

  const decodeChar = createDecodeEffect({ setDisplayChars, decodeSteps: 12 })

  useIntersectionObserver(
    [textRef],
    (_, { visible }) => {
      if (visible && !hasRevealed) {
        setHasRevealed(true)
        text.split('').forEach((char, index) => {
          if (displayChars[index] === '*') {
            decodeChar(index, char)
          }
        })
      }
    },
    {
      threshold: 0.5,
    }
  )

  return (
    <div ref={textRef}>
      {text.split('').map((_, i) => (
        <Fragment key={i}>{displayChars[i]}</Fragment>
      ))}
    </div>
  )
}
